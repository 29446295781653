import React, { useEffect, useRef, useState } from "react";
import { Link, NavLink, Outlet, useLocation } from "react-router-dom";
import { Container } from "react-bootstrap";
import Joyride, { STATUS } from "react-joyride";
// import
import TrustGridLogo from "../assets/images/trustgrid-ncc-logo-center.svg";
import GreterThen from "../assets/images/greterthen.svg";
import LessThen from "../assets/images/lessthen.svg";
import CollapseLogo from "../assets/images/collapse-trustgrid.svg";
import Icon from "../assets/images/why-iam.svg";
import "../assets/sass/app.scss";

const Root = () => {
  const [start, setStart] = useState(false);
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(false);
  const sidebarRef = useRef(null);

  // click out side menu collapsed on mobile screen
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        window.innerWidth <= 768 &&
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target)
      ) {
        setCollapsed(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [sidebarRef]);

  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  };

  const steps = [
    // sidebar menu wizard
    {
      target: ".dashboard-wiz",
      content: (
        <div style={{ textAlign: "left" }}>
          <p>
            The dashboard offers a snapshot of the consortium, displaying the
            count of onboarded issuers, verifiers, and created services.
          </p>
          <p>
            Additionally, it features graphical representations of verified
            identities, issued credentials, and the types of credentials issued.
          </p>
        </div>
      ),
      placement: "right",
      disableBeacon: true,
    },
    {
      target: ".my-account-wiz",
      content: (
        <div style={{ textAlign: "left" }}>
          <p>
            Manage your account settings. Add new admin users who can oversee
            the TrustGrid admin dashboard. Ensure effective administration by
            managing various services.
          </p>
        </div>
      ),
      placement: "right",
      disableBeacon: true,
    },
    {
      target: ".documentation-wiz",
      content: (
        <div style={{ textAlign: "left" }}>
          <p>
            Offers a concise overview of the TrustGrid platform, showcasing the
            Consortium, Member portals, Service Nodes, and Mobile application.
            It also includes a link to the comprehensive documentation available
            on the wiki.
          </p>
        </div>
      ),
      placement: "right",
      disableBeacon: true,
    },
    {
      target: ".manage-user-wiz",
      content: (
        <div style={{ textAlign: "left" }}>
          <p>
            Invite users, delegate consortium management permissions, or revoke
            them with ease.
          </p>
        </div>
      ),
      placement: "right",
      disableBeacon: true,
    },
    // dashobard home
    {
      target: ".consortium-card-wiz",
      content: (
        <div style={{ textAlign: "left" }}>
          <p>
            Click on a consortium card to view the consortium dashboard,
            view/add Issuers and Verifiers.
          </p>
        </div>
      ),
      disableBeacon: true,
      placement: "right",
    },
    {
      target: ".consortium-qr-wiz",
      content: (
        <div style={{ textAlign: "left" }}>
          <p>Click here to view the consortium QR code.</p>
          <p>You can copy and publish this QR code on external websites.</p>
        </div>
      ),
      disableBeacon: true,
    },
    {
      target: ".select-consortium-wiz",
      content: "Select consortium from the drop-down.",
      disableBeacon: true,
    },
    {
      target: ".create-consortium-step",
      content: (
        <div style={{ textAlign: "left" }}>
          <p>Click here to create a new consortium.</p>
          <p>
            A collaborative network of independent entities, such as
            organizations, companies, or individuals, that you want to
            collaborate with in a shared blockchain network.
          </p>
        </div>
      ),
      disableBeacon: true,
    },
    // my account
    {
      target: ".profile-wiz",
      content: "Click here to view and update the profile details.",
      disableBeacon: true,
      placement: "right",
    },
    {
      target: ".users-wiz",
      content: (
        <div style={{ textAlign: "left" }}>
          <p>Invite users and give them permission to manage consortiums</p>
        </div>
      ),
      disableBeacon: true,
      placement: "right",
    },
    {
      target: ".service-dashboard-wiz",
      content: (
        <div style={{ textAlign: "left" }}>
          <p>
            The dashboard offers a snapshot of the consortium, displaying the
            count of onboarded issuers, verifiers, and created services.
          </p>
          <p>
            Additionally, it features graphical representations of verified
            identities, issued credentials, and the variety of credentials
            distributed.
          </p>
        </div>
      ),
      disableBeacon: true,
      placement: "right",
    },
    {
      target: ".mobile-application-wiz",
      content: (
        <div style={{ textAlign: "left" }}>
          <p>
            Click here to download the TrustGrid Mobile application from the
            Play Store or App Store.
          </p>
        </div>
      ),
      disableBeacon: true,
      placement: "right",
    },
    // document
    {
      target: ".wiki-doc-wiz",
      content: (
        <div style={{ textAlign: "left" }}>
          <p>
            Click here to access the comprehensive documentation available on
            the wiki.
          </p>
        </div>
      ),
      disableBeacon: true,
      placement: "bottom",
    },
    // manage users
    {
      target: ".invite-user-wiz",
      content: (
        <div style={{ textAlign: "left" }}>
          <p>Add a new user to manage the consortium.</p>
        </div>
      ),
      disableBeacon: true,
      placement: "bottom",
    },
    {
      target: ".revoke-user-wiz",
      content: (
        <div style={{ textAlign: "left" }}>
          <p>Revoke user’s consortium management permission.</p>
        </div>
      ),
      disableBeacon: true,
      placement: "bottom",
    },
    // logout user
    {
      target: ".welcome-user",
      content: "Click here to access the logout option.",
      disableBeacon: true,
    },
  ];

  // Joyride Custom Style
  const joyrideStyles = {
    options: {
      zIndex: 10000,
      arrowColor: "#fff",
      backgroundColor: "#fff",
      primaryColor: "#4398CD",
      textColor: "#000",
      width: 400,
      height: 200,
    },
    tooltip: {
      fontSize: "16px",
    },
    buttonNext: {
      backgroundColor: "#4398CD",
      color: "#fff",
    },
    buttonBack: {
      color: "#000",
    },
    buttonSkip: {
      color: "#666",
    },
  };

  const handleJoyrideCallback = (data) => {
    const { status, action } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status) || action === "close") {
      setStart(false);
      document.body.style.overflow = "auto";
    } else {
      document.body.style.overflow = "hidden";
    }
  };

  // Define a mapping of routes to steps that should be excluded
  const routeExclusions = {
    "/account": [".my-account-wiz"],
    "/home": [".dashboard-wiz"],
    "/documentation": [".documentation-wiz"],
    "/manage-users": [".manage-user-wiz"],
  };

  // Get the current route exclusions, default to an empty array
  const exclusions = routeExclusions[location.pathname] || [];

  // Filter the steps based on the current route exclusions
  const filteredSteps = steps.filter(
    (step) => !exclusions.includes(step.target)
  );

  return (
    <Container fluid className="main-layout  p-0">
      <Joyride
        steps={filteredSteps}
        run={start}
        continuous={true}
        scrollToFirstStep={true}
        showSkipButton={true}
        styles={joyrideStyles}
        callback={handleJoyrideCallback}
        showProgress={false}
        locale={{
          last: "Finish",
        }}
      />
      <nav
        ref={sidebarRef}
        className={`sidebar-menu ${collapsed ? "collapsed-menu" : ""}`}
      >
        <button onClick={toggleSidebar} className="toggle-button">
          {collapsed ? (
            <img src={GreterThen} alt="logo" />
          ) : (
            <img src={LessThen} alt="logo" />
          )}
        </button>
        <div className="d-flex flex-column flex-shrink-0">
          <Link to="/">
            {collapsed ? (
              <div className="collapse-brand-logo">
                <img
                  src={CollapseLogo}
                  alt="logo"
                  width={48}
                  height={48}
                  className="img-fluid"
                />
              </div>
            ) : (
              <div className="brand-logo">
                <img src={TrustGridLogo} alt="logo" className="img-fluid" />
              </div>
            )}
          </Link>
          <ul className="nav nav-pills flex-column mb-auto nav-menu">
            <li className="nav-item">
              <NavLink to="/home" className="dashboard">
                {!collapsed ? (
                  <span className="dashboard-wiz">Dashboard</span>
                ) : (
                  ""
                )}
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/account" className="account">
                {!collapsed ? (
                  <span className="my-account-wiz">My Account</span>
                ) : (
                  ""
                )}
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/documentation" className="documentation">
                {!collapsed ? (
                  <span className="documentation-wiz">Documentation</span>
                ) : (
                  ""
                )}
              </NavLink>
            </li>
            {/* <li className="nav-item">
              <NavLink to="/manage-users" className="manage-users">
                {!collapsed ? (
                  <span className="manage-user-wiz">Manage Users</span>
                ) : (
                  ""
                )}
              </NavLink>
            </li> */}
          </ul>
        </div>
      </nav>
      <Outlet />
      <button className="wizard-btn" onClick={() => setStart(true)}>
        <img src={Icon} alt="icon" />
        Need Help?
      </button>
    </Container>
  );
};

export default Root;
