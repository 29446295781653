import routes from "./Router";
import { RouterProvider } from "react-router-dom";
import { NetworkProvider } from "./context/NetworkContext";
import { AdminProvider } from "./context/AdminContext";
import RenderOnAuthenticated from "./keycloak/RenderOnAuthenticated";
import RenderOnAnonymous from "./keycloak/RenderOnAnonymous";
import Welcome from "./pages/Welcome";
import "./assets/sass/app.scss";

function App() {
  return (
    <>
      <RenderOnAnonymous>
        <Welcome />
      </RenderOnAnonymous>
      <RenderOnAuthenticated>
        <NetworkProvider>
          <AdminProvider>
            <RouterProvider router={routes} />
          </AdminProvider>
        </NetworkProvider>
      </RenderOnAuthenticated>
    </>
  );
}

export default App;
