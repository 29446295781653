import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Col, Row, Spinner } from "react-bootstrap";
import Header from "../../Header/Header";
import { getUser, updateUser } from "../../../api/admin";
import { toast } from "react-toastify";
import {
  toastError,
  toastSuccess,
  toastWarning,
} from "../../Common/ToastAlert";
import TextField from "../../Common/TextField";

const Profile = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState();
  const [editable, setEditable] = useState(false);
  const [editButton, setEditButton] = useState(false);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isDirty },
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: {
      firstName: "",
      lastName: "",
      phoneNumber: "",
      organization: "",
      email: "",
    },
  });

  const errorMessage = {
    firstName: {
      required: "First Name is required",
      invalid: "Enter a valid Name",
      min: "Name should contain minimum of 3 characters",
    },
    lastName: {
      invalid: "Enter a valid Name",
    },
    phone: {
      invalid: "Enter a valid Phone Number",
    },
    organization: {
      required: "Organization Name is required",
      invalid: "Enter a valid Organization Name",
      min: "Organization Name should contain minimum of 3 characters",
    },
  };

  const fetchUserData = async () => {
    try {
      const response = await getUser(localStorage.getItem("userId"));
      const data = response.data;

      setValue("firstName", data.firstName || "");
      setValue("lastName", data.lastName || "");
      setValue("phoneNumber", data.phoneNumber || "");
      setValue("organization", data.organization || "");
      setValue("email", data.email || "");

      if (data.firstName && data.organization) {
        setEditable(true);
        setEditButton(true);
      } else {
        setEditable(false);
      }
    } catch (error) {
      toast.warning(
        "Hmm. Something doesn't seem right. Try refreshing or logging in again.",
        toastWarning()
      );
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  const updateUserDetails = async (formData) => {
    setLoading(true);
    try {
      const data = {
        ...formData,
        email: formData.email || "",
      };

      const onUpdate = await updateUser(data);

      if (onUpdate.status === 200) {
        toast.success("Profile updated successfully", toastSuccess());
        await fetchUserData(); // Refetch user data
      } else {
        toast.error(onUpdate.message, toastError());
      }
    } catch (error) {
      toast.warning(
        "Hmm. Something doesn't seem right. Try refreshing or logging in again.",
        toastWarning()
      );
    }
    setLoading(false);
  };

  const tooltipContent =
    "Use this screen to add details to your profile, add users to manage the consortium, access service dashboard to view consortium details and many more things...";

  return (
    <div className="right-container">
      <div className="main-header">
        <Header
          title="My Account"
          infoTooltip={true}
          tooltipContent={tooltipContent}
          tooltipPlacement="right"
        />
      </div>
      <div className="inner-body-container">
        <div className="my-account-card form-card">
          <div className="account-card-body">
            <form onSubmit={handleSubmit(updateUserDetails)}>
              <div className="profile-details">
                <div className="d-flex justify-content-between">
                  <div className="heading">Profile Details</div>
                  {editButton && editable && (
                    <button
                      type="button"
                      className="dsm-btn-secondary m-1"
                      onClick={() => setEditable(!editable)}
                    >
                      Edit Details
                    </button>
                  )}
                </div>
                <Row className="mb-3">
                  <Col xl={4} lg={4} md={4} sm={12}>
                    <Controller
                      control={control}
                      name="firstName"
                      render={({ field: { onChange, value, onBlur } }) => {
                        return (
                          <TextField
                            label="First Name"
                            type="text"
                            mandatory={true}
                            placeholder="Enter First Name"
                            maxLength={40}
                            onChange={onChange}
                            value={value ?? ""}
                            onBlur={onBlur}
                            error={errors?.firstName?.message}
                            disabled={editable}
                          />
                        );
                      }}
                      rules={{
                        required: errorMessage.firstName.required,
                        pattern: {
                          value: /^[A-Za-z ]+$/,
                          message: errorMessage.firstName.invalid,
                        },
                        minLength: {
                          value: 3,
                          message: errorMessage.firstName.min,
                        },
                      }}
                    />
                  </Col>
                  <Col xl={4} lg={4} md={4} sm={12}>
                    <Controller
                      control={control}
                      name="lastName"
                      render={({ field: { onChange, value, onBlur } }) => {
                        return (
                          <TextField
                            label="Last Name"
                            type="text"
                            placeholder="Enter Last Name"
                            maxLength={40}
                            onChange={onChange}
                            value={value ?? ""}
                            onBlur={onBlur}
                            error={errors?.lastName?.message}
                            disabled={editable}
                          />
                        );
                      }}
                      rules={{
                        pattern: {
                          value: /^[A-Za-z ]+$/,
                          message: errorMessage.lastName.invalid,
                        },
                      }}
                    />
                  </Col>
                </Row>{" "}
                <Row className="mb-3">
                  <Col xl={4} lg={4} md={4} sm={12}>
                    <Controller
                      control={control}
                      name="email"
                      render={({ field: { onChange, value, onBlur } }) => {
                        return (
                          <TextField
                            label="Email ID"
                            type="email"
                            placeholder="Enter Email ID"
                            onChange={onChange}
                            value={value ?? ""}
                            onBlur={onBlur}
                            disabled={true}
                          />
                        );
                      }}
                    />
                  </Col>
                  <Col xl={4} lg={4} md={4} sm={12}>
                    <Controller
                      control={control}
                      name="phoneNumber"
                      render={({ field: { onChange, value, onBlur } }) => (
                        <TextField
                          label="Phone Number"
                          type="text"
                          placeholder="Enter Phone Number"
                          maxLength={40}
                          onChange={onChange}
                          value={value ?? ""}
                          onBlur={onBlur}
                          error={errors?.phoneNumber?.message}
                          disabled={editable}
                        />
                      )}
                      rules={{
                        pattern: {
                          value: /^[0-9]+$/,
                          message: errorMessage.phone.invalid,
                        },
                      }}
                    />
                  </Col>
                </Row>
                <Row className="mb-4">
                  <Col xl={4} lg={4} md={4} sm={12}>
                    <Controller
                      control={control}
                      name="organization"
                      render={({ field: { onChange, value, onBlur } }) => (
                        <TextField
                          mandatory={true}
                          label="Organization Name"
                          type="text"
                          placeholder="Enter Organization Name"
                          maxLength={40}
                          onChange={onChange}
                          value={value ?? ""}
                          onBlur={onBlur}
                          error={errors?.organization?.message}
                          disabled={editable}
                        />
                      )}
                      rules={{
                        required: errorMessage.organization.required,
                        pattern: {
                          value: /^[A-Za-z0-9 ]+$/,
                          message: errorMessage.organization.invalid,
                        },
                        minLength: {
                          value: 3,
                          message: errorMessage.organization.min,
                        },
                      }}
                    />
                  </Col>
                </Row>
              </div>

              <div className="inner-page-footer">
                {!editable && (
                  <React.Fragment>
                    <button
                      type="submit"
                      className="dsm-btn-primary"
                      disabled={loading || editable || !isDirty}
                    >
                      {loading ? (
                        <Spinner animation="border" size="sm" variant="light" />
                      ) : (
                        "Save"
                      )}
                    </button>

                    <button
                      type="button"
                      className="dsm-btn-secondary"
                      onClick={() => navigate("/account")}
                    >
                      Back
                    </button>
                  </React.Fragment>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
