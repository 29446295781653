import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

// export const data = {
//   labels: ["Offer-Sent", "Done"],
//   datasets: [
//     {
//       label: "Credential",
//       data: [1000, 5000],
//       backgroundColor: ["#B0615F", "#2D78F3"],
//       borderColor: ["#ffffff", "#ffffff"],
//       borderWidth: 1,
//     },
//   ],
// };

const CredentialTypeChart = ({ offerSent, done }) => {
  return (
    <>
      <Pie
        data={{
          labels: ["Offer-Sent", "Done"],
          datasets: [
            {
              label: "Credential",
              data: [offerSent, done],
              backgroundColor: ["#B0615F", "#5cbd99"],
              borderColor: ["#ffffff", "#ffffff"],
              borderWidth: 1,
            },
          ],
        }}
      />
    </>
  );
};

export default CredentialTypeChart;
