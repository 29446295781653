import React, { useContext, useEffect, useState } from "react";
import Select from "react-select";
import { Spinner, Table } from "react-bootstrap";
import Header from "../../Header/Header";
import DeleteIcon from "../../../assets/images/delete.svg";
import InviteUser from "./InviteUser";
import { allNetworksUsers, getNetworkByUserId } from "../../../api/admin";
import { toast } from "react-toastify";
import { toastWarning } from "../../Common/ToastAlert";
import { AdminContext } from "../../../context/AdminContext";
import customStyles from "../../../assets/sass/Common/selectStyles";
import EmptyScreen from "../../Common/EmptyScreen";
import { formatDate } from "../../../util/inputvalidations";
import NoDataImage from "../../../assets/images/no-added-data.svg";
import DeleteUser from "./DeleteUser";

const ManageUsers = () => {
  const { userId } = useContext(AdminContext);
  const [network, setNetwork] = useState([]);
  const [selectedOption, setSelectedOption] = useState(
    network ? network[0] : []
  );
  const [selectedNetworkId, setSelectedNetworkId] = useState(
    selectedOption?.id
  );

  const [networkUsers, setnetworkUsers] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openDeleteUserModal, setOpenDeleteUserModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [deleteUser, setDeleteUser] = useState(null);

  const options =
    network?.map((network) => {
      return {
        value: network.name,
        label: network.name,
        id: network.id,
        regionId: network.regionId,
      };
    }) || [];
  const fetchAllNetworkData = async () => {
    try {
      setLoading(true);
      const getNetwork = await getNetworkByUserId({
        userId: userId,
      });
      const res = getNetwork.data.result;
      setNetwork(res);
      if (res[0]) {
        const defaultOption = {
          value: res[0].name,
          label: res[0].name,
          id: res[0].id,
          regionId: res[0].regionId,
        };
        setSelectedOption(defaultOption);
        setSelectedNetworkId(defaultOption.id);
      }

      setLoading(false);
    } catch (error) {
      toast.warning(error.response.data.message, toastWarning());
      toast.warning(
        "Hmm. Something doesn't seem right. Try refreshing or logging in again.",
        toastWarning()
      );
    }
  };

  const fetchAllNetworksUsers = async (selectedNetworkId) => {
    try {
      setLoading(true);
      const users = await allNetworksUsers(selectedNetworkId);
      const res = users.data.data;
      setnetworkUsers(res);
      setLoading(false);
    } catch (err) {
      setError(err);
      toast.warning(
        "Hmm. Something doesn't seem right. Try refreshing or logging in again.",
        toastWarning()
      );
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAllNetworkData();
  }, []);

  useEffect(() => {
    if (selectedNetworkId) {
      fetchAllNetworksUsers(selectedNetworkId);
    }
  }, [selectedNetworkId]);

  const handleSelectChange = (option) => {
    setSelectedOption(option);
    setSelectedNetworkId(option?.id);
    fetchAllNetworksUsers(option?.id);
  };

  const allUpdatedUsersData = networkUsers?.sort(
    (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
  );

  const handleOpenDeleteUserModal = (data) => {
    setDeleteUser(data);
    setOpenDeleteUserModal(true);
  };

  // No results content
  const noAddedContent = {
    heading: "No Users are added yet on this Consortium",
    subHeading: ["Send Invitation to users on the Consortium"],
  };

  return (
    <React.Fragment>
      {openModal && (
        <InviteUser
          show={openModal}
          handleClose={() => setOpenModal(false)}
          refetchData={() => fetchAllNetworksUsers(selectedNetworkId)}
        />
      )}{" "}
      {openDeleteUserModal && (
        <DeleteUser
          show={openDeleteUserModal}
          handleClose={() => setOpenDeleteUserModal(false)}
          refetchData={() => fetchAllNetworksUsers(selectedNetworkId)}
          data={deleteUser}
        />
      )}
      <div className="right-container">
        <div className="main-header">
          <Header title="Manage Users" />
        </div>
        <div className="inner-body-container">
          {" "}
          <div className="requests-container">
            <div className="request-header">
              <div className="d-flex align-items-center gap-2">
                <lablel htmlFor="select-consortium" className="inputbox-label">
                  Consortium:
                </lablel>
                <div style={{ width: "250px", maxWidth: "500px", zIndex: 999 }}>
                  <Select
                    styles={customStyles}
                    closeMenuOnSelect={true}
                    onChange={handleSelectChange}
                    options={options}
                    defaultValue={selectedOption}
                    placeholder={
                      selectedOption ? selectedOption.label : "Select Network"
                    }
                  />
                </div>
              </div>
              <div className="custom-select d-flex align-items-center justify-content-between invite-user-wiz">
                <div className="select-box">
                  <button
                    type="button"
                    className="dsm-btn-primary"
                    onClick={() => setOpenModal(true)}
                  >
                    Invite User
                  </button>
                </div>
              </div>
            </div>
            <div className="requests-body">
              <Table borderless className="requests-table submit-proof-table">
                <thead>
                  <tr>
                    <th>User Email</th>
                    <th style={{ textAlign: "center" }}>Date</th>
                    <th style={{ textAlign: "center" }}>Status</th>
                    <th style={{ textAlign: "center" }}>Revoke</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr className="empty-screen-tr">
                      <td colSpan={6} rowSpan={8}>
                        <div className="mt-4 d-flex align-items-center justify-content-center">
                          <Spinner animation="border" variant="primary" />
                        </div>
                      </td>
                    </tr>
                  ) : allUpdatedUsersData?.length === 0 ? (
                    <tr className="empty-screen-tr">
                      <td colSpan={6}>
                        <EmptyScreen
                          content={noAddedContent}
                          emptyScreenImage={NoDataImage}
                        />
                      </td>
                    </tr>
                  ) : (
                    allUpdatedUsersData?.map((user) => (
                      <tr key={user?.id}>
                        <td>
                          <span
                            style={{
                              color: "#4398CD",
                            }}
                          >
                            {user?.email}
                          </span>
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {formatDate(user?.createdAt)}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          <span
                            className={
                              user?.active === true
                                ? "success-badge"
                                : "pending-badge"
                            }
                          >
                            {user?.active === true ? "Active" : "Pending"}
                          </span>
                        </td>
                        <td style={{ textAlign: "center" }}>
                          <div className="d-flex justify-content-center gap-2">
                            <button
                              type="button"
                              className="dsm-btn-danger-light icon-btn revoke-user-wiz"
                              onClick={() => handleOpenDeleteUserModal(user)}
                            >
                              <img src={DeleteIcon} alt="icon" />
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ManageUsers;
