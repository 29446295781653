import React, { useContext } from "react";
import { Col, Row, Stack } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import CreateConsortiumImage from "../../../../../assets/images/create-consortium/create-new-consortium.svg";
import { AdminContext } from "../../../../../context/AdminContext";
import TextField from "../../../../Common/TextField";
import TextArea from "../../../../Common/TextArea";

const ConsortiumDetails = ({ setCurrentStep }) => {
  const navigate = useNavigate();
  const { addNetwork, updateNetworkData } = useContext(AdminContext);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: {
      consortiumName: addNetwork?.name || "",
      description: addNetwork?.description || "",
    },
  });

  const errorMessage = {
    consortiumName: {
      required: "Consortium Name is required",
      invalid: "Enter a valid Consortium Name",
      min: "Consortium Name should contain minimum of 3 characters.",
      max: "Consortium Name should not exceed 40 characters.",
    },
    description: {
      required: "Description is required",
      invalid: "Enter a valid Description",
    },
  };

  const goNextStep = (data) => {
    updateNetworkData({
      name: data?.consortiumName,
      description: data?.description,
    });
    setCurrentStep(2);
  };

  const handleBack = () => {
    reset(
      updateNetworkData({
        name: "",
        description: "",
      })
    );
    navigate(-1);
  };

  return (
    <div className="consortium-details">
      <Row>
        <Col xl={6} lg={6} md={12} sm={12}>
          <img src={CreateConsortiumImage} alt="" class="img-fluid" />
          <div className="mt-4">
            <p>
              A consortium is a private blockchain network and is hosted within
              a specific region of your choice, utilizing nodes to maintain
              network integrity and performance. The Node architecture provides
              high availability and redundancy through multiple isolated data
              centers, ensuring robust performance and GDPR compliance.
            </p>
            <p>
              Region selection depends on where your organization does business
              and where most of your end users reside to ensure transaction and
              data sovereignty. Choose the region closest to your location for
              better latency, performance, and user experience.
            </p>
          </div>
        </Col>
        <Col xl={6} lg={6} md={12} sm={12} className="mt-4">
          <div className="input-details">
            <Row className="mb-2 justify-content-md-center">
              <Col lg={8} xl={8} md={8}>
                <Stack gap={2}>
                  <form onSubmit={handleSubmit(goNextStep)}>
                    <div className="mb-3">
                      <Controller
                        control={control}
                        name="consortiumName"
                        render={({ field: { onChange, value, onBlur } }) => {
                          return (
                            <TextField
                              label="Consortium Name"
                              type="text"
                              mandatory={true}
                              placeholder="Enter Consortium Name"
                              onChange={onChange}
                              value={value ?? ""}
                              onBlur={onBlur}
                              error={errors?.consortiumName?.message}
                            />
                          );
                        }}
                        rules={{
                          required: errorMessage.consortiumName.required,
                          pattern: {
                            value: /^[A-Za-z0-9 ]+$/,
                            message: errorMessage.consortiumName.invalid,
                          },
                          minLength: {
                            value: 3,
                            message: errorMessage.consortiumName.min,
                          },
                          maxLength: {
                            value: 40,
                            message: errorMessage.consortiumName.max,
                          },
                        }}
                      />
                    </div>
                    <div className="mb-5">
                      <Controller
                        control={control}
                        name="description"
                        render={({ field: { onChange, value, onBlur } }) => {
                          return (
                            <TextArea
                              label="Description"
                              type="text"
                              mandatory={true}
                              placeholder="Add Description here"
                              maxLength={150}
                              onChange={onChange}
                              value={value ?? ""}
                              onBlur={onBlur}
                              error={errors?.description?.message}
                            />
                          );
                        }}
                        rules={{
                          required: errorMessage.description.required,
                          pattern: {
                            value: /^[A-Za-z0-9 ]+$/,
                            message: errorMessage.description.invalid,
                          },
                        }}
                      />
                    </div>
                    <div className="d-flex align-items-center gap-2">
                      <button
                        type="button"
                        className="dsm-btn-secondary w-50"
                        onClick={handleBack}
                      >
                        Back
                      </button>{" "}
                      <button type="submit" className="dsm-btn-primary w-50">
                        Next
                      </button>
                    </div>
                  </form>
                </Stack>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ConsortiumDetails;
