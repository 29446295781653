import React from "react";

const NodeWidget = ({
  nodePort,
  nodeIp,
  alias,
  services,
  from,
  uptime,
  sqNo,
}) => {
  function TimeAgoNode(uptimeSeconds) {
    // var seconds = uptimeSeconds % 60;
    // var minutes = Math.floor((uptimeSeconds % 3600) / 60);
    var hours = Math.floor((uptimeSeconds % 86400) / 3600);
    var days = Math.floor(uptimeSeconds / 86400);

    var uptimeParts = [];

    if (days) {
      uptimeParts.push(`${days} days`);
    }
    if (hours || uptimeParts.length) {
      uptimeParts.push(`${hours} hours`);
    }
    // if (minutes || uptimeParts.length) {
    //   uptimeParts.push(`${minutes} mins`);
    // }
    // //TODO - Fix Auto Refresh
    // uptimeParts.push(`${seconds} seconds`);

    return uptimeParts.join(", ");
  }

  return (
    <div className=" nodes nodes-card  border-start border-5 border-success-subtle">
      <a
        href={"https://explorer.dev.trustgrid.com/tx/TrustGridDev/pool/" + sqNo}
        target="_blank"
        className="text-decoration-none"
      >
        <div className="title-2 row mb-2">
          <span className="node-title col-2">Alias </span>
          <span className="node-subtext col-4"> {alias} </span>
          <span className="node-title col-2">Node IP </span>
          <span className="node-subtext col-4"> {nodeIp} </span>
        </div>
        <div className="title-2 row">
          <span className="node-title col-2">Port </span>
          <span className="node-subtext col-4"> {nodePort} </span>
          <span className="node-title col-2">Services </span>
          <span className="node-subtext col-4"> {services} </span>
        </div>
        <hr />
        <div className="title-2 row">
          <span className="node-title col-2">DID </span>
          <span className="node-subtext col-10"> {from} </span>
        </div>
        <hr />

        <div className="title-2 row mb-1">
          <span className="node-title col-2">Uptime</span>
          <span className="node-subtext col-10">{TimeAgoNode(uptime)} </span>
        </div>
      </a>
    </div>
  );
};

export default NodeWidget;
