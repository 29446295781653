import React from "react";
import { useNavigate } from "react-router-dom";
import CreateConsortium from "../../../assets/images/create-new-consortium.svg";

const Welcome = () => {
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <div className="welcome-page">
        <h1>
          Welcome to{" "}
          <span className="brand-name trustgrid-about">
            TrustGrid<sup>TM</sup>
          </span>
        </h1>
        <div className="description">
          <span>
            TrustGrid<sup>TM</sup>
          </span>{" "}
          is the trusted network that enables your organization to build
          decentralized trust domains with your consumers, business partners and
          other agencies.
        </div>
        <div class="d-flex gap-1">
          <div className="welcome-card">
            <img src={CreateConsortium} alt="img" />
            <div className="title">
              Create a multiparty consortium and deploy secure <br />
              digital services
            </div>
            <button
              class="dsm-btn-primary create-consortium-step"
              onClick={() => navigate("/home/create-consortium")}
            >
              Create New Consortium
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Welcome;
