import React from "react";
import { Col, Row } from "react-bootstrap";
import Header from "../../Header/Header";
import MobileApplicationImage from "../../../assets/images/myaccount/mobile-applications.svg";
import IosImage from "../../../assets/images/myaccount/ios.svg";
import AndroidImage from "../../../assets/images/myaccount/android.svg";

const MobileApplication = () => {
  const tooltipContent =
    "Use this screen to add details to your profile, add users to manage the consortium, access service dashboard to view consortium details and many more things...";

  return (
    <div className="right-container">
      <div className="main-header">
        <Header
          title="My Account"
          infoTooltip={true}
          tooltipContent={tooltipContent}
          tooltipPlacement="right"
        />
      </div>
      <div className="inner-body-container">
        <div className="my-account-card">
          <div className="account-card-body">
            <Row>
              <Col xl={6} lg={6} md={6} sm={12} xm={12}>
                <div className="heading">TrustGrid App</div>
                <div className="app-download-btngroup">
                  <a
                    href="https://apps.apple.com/us/app/vrtyou/id1506714157"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="dsm-btn-primary app-download-btn"
                  >
                    <img src={IosImage} alt="img" className="btn-icon" />
                    Download From <br />
                    App Store
                  </a>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.securelogicgroup.trustgrid.citizenapp"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="dsm-btn-primary app-download-btn"
                  >
                    <img src={AndroidImage} alt="img" className="btn-icon" />
                    Download From <br />
                    Play Store
                  </a>
                </div>
              </Col>
              <Col xl={6} lg={6} md={6} sm={12} xm={12}>
                <div className="img-card">
                  <img src={MobileApplicationImage} alt="" class="img-fluid" />
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};
export default MobileApplication;
