// Dropdown.js
import React, { useContext, useState } from "react";
import { DropdownItem, DropdownMenu } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";

import LogoutIcon from "../../assets/images/logout.svg";
import UserIcon from "../../assets/images/logout-user.svg";
import LogoutPopup from "./LogoutPopup";
import { AdminContext } from "../../context/AdminContext";

const LogoutDropdown = () => {
  const { username } = useContext(AdminContext);
  const [openModal, setOpenModal] = useState(false);

  return (
    <React.Fragment>
      {openModal && (
        <LogoutPopup show={openModal} handleClose={() => setOpenModal(false)} />
      )}
      <div className="logout-main">
        <p className="user-name">{username ? username : "Hello User"}</p>
        <Dropdown>
          <Dropdown.Toggle
            variant="success"
            className="dropdown-toggle"
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <img src={UserIcon} alt="user-icon" />
          </Dropdown.Toggle>
          <DropdownMenu
            className="dropdown-menu"
            aria-labelledby="dropdownMenuButton"
          >
            <DropdownItem>
              <button
                className="dropdown-item"
                onClick={() => setOpenModal(true)}
              >
                <img src={LogoutIcon} alt="logout" />
                <span className="logout-text">Logout</span>
              </button>
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </div>
    </React.Fragment>
  );
};

export default LogoutDropdown;
