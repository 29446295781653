import React, { useState, createContext, useEffect } from "react";
import UserService from "../keycloak/UserService";

export const AdminContext = createContext();

export const AdminProvider = ({ children }) => {
  const currusername = UserService.getUsernames();
  const [data, setData] = useState([]);
  const [userId, setUserId] = useState("");
  const [username, setUsername] = useState(currusername);

  const [addNetwork, setAddNetwork] = useState({
    createdBy: userId ? parseInt(userId) : '',
    name: "",
    description: "",
    status: "up",
    publicServicesURL: "https://example.com",
    regionId: "southeastasia",
    confidentialCompute: "Yes",
    numberOfNodes: 10,
    networkStatus: "Active",
    versionTag: "v1.0",
    active: true,
  });
  const [addAdmin, setAddAdmin] = useState({
    email: "",
    networkId: "",
  });

  const updateAdminData = (data) => {
    setAddAdmin((prev) => ({ ...prev, ...data }));
  };

  const updateNetworkData = (data) => {
    setAddNetwork((prev) => ({ ...prev, ...data }));
  };

  useEffect(() => {
    setAddNetwork((prev) => ({ ...prev, createdBy: userId }));
  }, [userId]);

  return (
    <AdminContext.Provider
      value={{
        data,
        setData,
        addNetwork,
        setAddNetwork,
        updateNetworkData,
        addAdmin,
        setAddAdmin,
        updateAdminData,
        userId,
        setUserId,
        username,
        setUsername,
      }}
    >
      {children}
    </AdminContext.Provider>
  );
};
