import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import Welcome from "./Welcome";
import Header from "../../Header/Header";
import AllConsortiumList from "./AllConsortiumList";
import { getNetworkByUserId, getUserByEmail } from "../../../api/admin";
import UserService from "../../../keycloak/UserService";

import { toastWarning } from "../../Common/ToastAlert";
import { AdminContext } from "../../../context/AdminContext";

const Dashboard = () => {
  const { userId, setUserId } = useContext(AdminContext);
  const [network, setNetwork] = useState([]);
  const [initialised, setInitial] = useState(false);

  useEffect(() => {
    const setLoggedIn = async () => {
      if (!sessionStorage.getItem("dataInitialized")) {
        const email = UserService.getUsername()?.email;
        try {
          const sendEmail = await getUserByEmail({ email: email });
          const result = sendEmail?.data?.result;
          const id = result?.id;
          localStorage.setItem("userId", id);
          setUserId(id);

          sessionStorage.setItem("dataInitialized", true);
          setInitial(true);
        } catch (error) {
          toast.warning(error.response.data.message, toastWarning());
          toast.warning(
            "Hmm. Something doesn't seem right. Try refreshing or logging in again.",
            toastWarning()
          );
        }
      } else {
        const id = localStorage.getItem("userId");
        setUserId(id);
        setInitial(true);
      }
    };
    setLoggedIn();
  }, [setUserId]);

  const fetchAllNetworkData = async () => {
    if (userId) {
      try {
        const getNetwork = await getNetworkByUserId({ userId: userId });
        const res = getNetwork.data.result;
        setNetwork(res);
        localStorage.setItem("networkLength", res.length);
      } catch (error) {
        toast.warning("Failed to fetch network data", toastWarning());
      }
    }
  };

  useEffect(() => {
    if (initialised && userId) {
      fetchAllNetworkData();
    }
  }, [initialised, userId]);

  return (
    <div className="right-container">
      <div className="main-header">
        <Header title="Dashboard" />
      </div>
      <div className="inner-body-container">
        {!network.length == 0 ? (
          <AllConsortiumList />
        ) : (
          <Welcome refetchData={fetchAllNetworkData} />
        )}
      </div>
    </div>
  );
};

export default Dashboard;
