import React, { useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { InviteIssuer } from "../../api/network";
import { toastError, toastSuccess, toastWarning } from "../Common/ToastAlert";
import TextField from "../Common/TextField";

const AddNewIssuerModal = ({ show, handleClose, refetchData }) => {
  const networkId = localStorage.getItem("networkId");
  const [loading, setLoading] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
  });

  const errorMessage = {
    name: {
      required: "Name is required",
      invalid: "Enter a valid  Name",
      min: "Name should contain minimum of 3 characters",
    },
    email: {
      required: "Email ID is required",
      invalid: "Enter a valid Email ID",
    },
  };

  const onClickInviteIssuer = async (data) => {
    setLoading(true);
    try {
      const payload = { ...data, networkId };
      const response = await InviteIssuer(payload);
      setLoading(false);
      if (response.status === 200) {
        toast.success("Invite sent successfully", toastSuccess());
        handleClose();
        refetchData();
      } else {
        toast.error("Failed to invite", toastError());
      }
    } catch (error) {
      toast.warning(
        "Email ID already exists! Please try with another email",
        toastWarning()
      );
      setLoading(false);
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      className="dsm-modal-popup"
      size="Md"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Create Issuer Organization</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mb-3">
          <Controller
            control={control}
            name="orgName"
            render={({ field: { onChange, value, onBlur } }) => {
              return (
                <TextField
                  label="Name"
                  type="text"
                  placeholder="Enter Name"
                  onChange={onChange}
                  value={value ?? ""}
                  onBlur={onBlur}
                  error={errors?.orgName?.message}
                  mandatory={true}
                />
              );
            }}
            rules={{
              required: errorMessage.name.required,
              pattern: {
                value: /^[A-Za-z0-9 ]+$/,
                message: errorMessage.name.invalid,
              },
              minLength: {
                value: 3,
                message: errorMessage.name.min,
              },
            }}
          />
        </div>
        <div className="mb-3">
          <Controller
            control={control}
            name="email"
            render={({ field: { onChange, value, onBlur } }) => {
              return (
                <TextField
                  label="Email ID"
                  type="text"
                  placeholder="Enter Email ID"
                  onChange={onChange}
                  value={value?.toLowerCase() ?? ""}
                  onBlur={onBlur}
                  error={errors?.email?.message}
                  mandatory={true}
                />
              );
            }}
            rules={{
              required: errorMessage.email.required,
              pattern: {
                value: /^[a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                message: errorMessage.email.invalid,
              },
            }}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="dsm-btn-secondary"
          onClick={handleClose}
        >
          Cancel
        </button>
        <button
          type="button"
          className="dsm-btn-primary"
          onClick={handleSubmit(onClickInviteIssuer)}
        >
          {loading ? (
            <Spinner animation="border" size="sm" variant="light" />
          ) : (
            "Save"
          )}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddNewIssuerModal;
