import React from "react";
import { Modal, Spinner } from "react-bootstrap";
import { formatDate } from "../../util/inputvalidations";

const PublishedClaimView = ({ show, handleClose, data }) => {
  const extractStringValues = (obj) => {
    return Object.entries(obj)
      .filter(([key, value]) => typeof value === "string")
      .map(([key, value]) => value)
      .join("");
  };

  const selectedAttributes = data?.attributes
    .filter((attribute) => attribute.selected)
    .map((attribute) => extractStringValues(attribute));

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="md"
      className="dsm-modal-popup"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {data?.vcName}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <label htmlFor="update-at" className="mb-0 inputbox-label">
            Published On{" "}
          </label>
          <div className="d-flex align-items-center gap-2 mb-3">
            <p style={{ color: "1C1C1C", marginBottom: 0 }}>
              <small>{formatDate(data?.updatedAt)}</small>
            </p>
          </div>
          <label htmlFor="update-at" className="mb-2 inputbox-label">
            Attributes
          </label>
          {selectedAttributes?.map((a, i) => (
            <div className="mb-1">
              <label
                className="text-capitalize"
                style={{ color: "#777777" }}
                key={i}
              >
                {a}
              </label>
            </div>
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="dsm-btn-secondary"
          onClick={handleClose}
        >
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default PublishedClaimView;
