import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
  },
};

const labels = ["Mar", "Apr", "May"];

// export const data = {
//   labels,
//   datasets: [
//     {
//       label: "Monthly Issued",
//       data: [0, 0, count],
//       backgroundColor: "#2D78F3",
//     },
//   ],
// };

const IssuedCredentialChart = ({ count }) => {
  return (
    <>
      <Bar
        options={options}
        data={{
          labels,
          datasets: [
            {
              label: "Monthly Issued",
              data: [0, 0, count],
              backgroundColor: "#2D78F3",
            },
          ],
        }}
      />
    </>
  );
};

export default IssuedCredentialChart;
