import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

import Header from "../Header/Header";
// Images
import CheckCircle from "../../assets/images/check-circle-duotone.svg";
import Issuers from "../../assets/images/issuers.svg";
import Verifiers from "../../assets/images/verifiers.svg";
import Services from "../../assets/images/services.svg";
import CredentialTypeChart from "./Charts/CredentialTypeChart";
import IssuedCredentialChart from "./Charts/IssuedCredentialChart";
import VerifiedCredentialChart from "./Charts/VerifiedIdentitiesChart";
import NodeWidget from "../Common/NodeWidget";

import {
  getAllIssuersCount,
  getAllServicesCount,
  getAllVerifiersCount,
  getPoolTxs,
  getIssuedCredentials,
  getUptime,
} from "../../api/network";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import { toastWarning } from "../Common/ToastAlert";
import Schema from "./Schema";
import Txs from "./Txs";

const NetworkDashboard = () => {
  const networkId = localStorage.getItem("networkId");
  const [loading, setLoading] = useState(true);
  const [issuerCount, setIssuerCount] = useState();
  const [verifierCount, setVerifierCount] = useState();
  const [servicesCount, setServicesCount] = useState();
  const [nodes, setNodes] = useState([]);
  const [issuedCredentials, setIssuedCredentials] = useState([]);
  const [count, setCount] = useState({});
  const [uptime, setUptime] = useState([]);

  const setCounts = (allConnections) => {
    const data = {
      offerSent: 0,
      done: 0,
    };
    allConnections.map((x) => {
      if (x.state === "offer-sent") data.offerSent += 1;
      else data.done += 1;
    });
    setCount(Object.assign({}, data));
  };

  useEffect(() => {
    const fetchPoolData = async () => {
      try {
        const response = await getPoolTxs();
        const data = response.data;

        const nodeArray = data
          .reverse()
          .map((item) => {
            if (
              item.idata &&
              item.idata.serialized &&
              item.idata.serialized.idata
            ) {
              const txn = JSON.parse(item.idata.serialized.idata.json).txn;
              const meta = JSON.parse(item.idata.serialized.idata.json).txn;
              const txnType = item.idata.expansion.idata.txn.typeName;
              if (txnType === "NODE") {
                const nodeInfo = txn.data.data;
                return {
                  seqNo: item.imeta.seqNo,
                  nodeIp: nodeInfo.node_ip,
                  alias: nodeInfo.alias,
                  services: nodeInfo.services,
                  nodePort: nodeInfo.node_port,
                  from: meta.metadata.from,
                  // sqNo: meta.txnMetadata.seqNo
                };
              }
            }
            return null;
          })
          .filter((node) => node !== null);

        setNodes(nodeArray);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchPoolData();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  useEffect(() => {
    const fetchIssuedCredentials = async () => {
      try {
        setLoading(true);
        const getCredential = await getIssuedCredentials();
        const res = getCredential.data.result;
        setIssuedCredentials(res);
        setCounts(res);
        setLoading(false);
      } catch (error) {
        // toast.warning(
        //   "Hmm... Something doesn't seem right. Try refreshing or logging in again.",
        //   toastWarning()
        // );
        setLoading(false);
      }
    };
    fetchIssuedCredentials();
  }, []);

  useEffect(() => {
    const fetchCounts = async () => {
      try {
        setLoading(true);
        if (networkId) {
          const [issuersResponse, verifiersResponse, servicesResponse] =
            await Promise.all([
              getAllIssuersCount(networkId),
              getAllVerifiersCount(networkId),
              getAllServicesCount(networkId),
            ]);
          setIssuerCount(issuersResponse.data.IssuerCount);
          setVerifierCount(verifiersResponse.data.VerifierCount);
          setServicesCount(servicesResponse.data.ServiceCount);
        }
      } catch (error) {
        toast.warning(
          "Hmm. Something doesn't seem right. Try refreshing or logging in again.",
          toastWarning()
        );
      } finally {
        setLoading(false);
      }
    };

    const fetchUptimes = async () => {
      try {
        const onFetch = await getUptime();
        const res = onFetch?.data?.validators;
        setUptime(res);
      } catch (error) {
      }
    };

    fetchCounts();
    fetchUptimes();
  }, [networkId]);

  return (
    <div className="right-container">
      <div className="main-header">
        <Header title={`${localStorage.getItem("networkName")}`} />
      </div>
      <div className="inner-body-container">
        <div className="home-body">
          <div className="update-section">
            <Row>
              <Col md={6} lg={4} sm={12} xs={12} className="mb-4">
                <div className="update-card all-issuer-card-wiz">
                  {loading ? (
                    <Skeleton
                      count={1}
                      width={48}
                      height={48}
                      borderRadius={50}
                    />
                  ) : (
                    <img src={Issuers} alt="img" />
                  )}
                  <div className="card-right">
                    <div className="title-1">
                      {loading ? (
                        <Skeleton count={1} width={150} height={48} />
                      ) : (
                        `${issuerCount ?? ""} Issuers`
                      )}
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={6} lg={4} sm={12} xs={12} className="mb-4">
                <div className="update-card all-verifier-card-wiz">
                  {loading ? (
                    <Skeleton
                      count={1}
                      width={48}
                      height={48}
                      borderRadius={50}
                    />
                  ) : (
                    <img src={Verifiers} alt="img" />
                  )}
                  <div className="card-right">
                    <div className="title-1">
                      {loading ? (
                        <Skeleton count={1} width={150} height={48} />
                      ) : (
                        `${verifierCount ?? ""} Verifiers`
                      )}
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={6} lg={4} sm={12} xs={12} className="mb-4">
                <div className="update-card all-services-card-wiz">
                  {loading ? (
                    <Skeleton
                      count={1}
                      width={48}
                      height={48}
                      borderRadius={50}
                    />
                  ) : (
                    <img src={Services} alt="img" />
                  )}
                  <div className="card-right">
                    <div className="title-1">
                      {loading ? (
                        <Skeleton count={1} width={150} height={48} />
                      ) : (
                        `${servicesCount ?? ""} Services`
                      )}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="chart-graph-section">
            <Row>
              <Col md={6} lg={4} sm={12} xs={12} className="mb-4">
                <div className="graph-card issued-credential-wiz">
                  <div className="title-2">
                    {loading ? (
                      <Skeleton count={1} width={250} height={30} />
                    ) : (
                      "Issued Credentials"
                    )}
                  </div>
                  <div className="credential-type-chart">
                    {loading ? (
                      <Skeleton count={1} width={300} height={150} />
                    ) : (
                      <IssuedCredentialChart count={issuedCredentials.length} />
                    )}
                  </div>
                  {loading ? (
                    <Skeleton count={1} width={250} height={40} />
                  ) : (
                    <div className="title-1">
                      {issuedCredentials.length} Per Month
                    </div>
                  )}
                </div>
              </Col>
              <Col md={6} lg={4} sm={12} xs={12} className="mb-4">
                <div className="graph-card verified-identity-wiz">
                  <div className="title-2">
                    {loading ? (
                      <Skeleton count={1} width={250} height={30} />
                    ) : (
                      "Verified Holders"
                    )}
                  </div>
                  <div className="credential-type-chart">
                    {loading ? (
                      <Skeleton count={1} width={300} height={150} />
                    ) : (
                      <VerifiedCredentialChart count={verifierCount} />
                    )}
                  </div>
                  {loading ? (
                    <Skeleton count={1} width={250} height={40} />
                  ) : (
                    <div className="title-1">{verifierCount} Per Month</div>
                  )}
                </div>
              </Col>
              <Col md={6} lg={4} sm={12} xs={12} className="mb-4">
                <div className="graph-card credential-type-wiz">
                  <div className="title-2 mb-2">
                    {loading ? (
                      <Skeleton count={1} width={250} height={30} />
                    ) : (
                      "Credential Status"
                    )}
                  </div>
                  <div className="credential-type-chart">
                    {loading ? (
                      <Skeleton count={1} width={260} height={250} />
                    ) : (
                      <CredentialTypeChart
                        offerSent={count.offerSent}
                        done={count.done}
                      />
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <Col md={12} lg={12} sm={12} xs={12}>
            <div className="d-flex justify-content-center align-items-center update-card health-card">
              {loading ? (
                <Skeleton count={1} width={48} height={48} borderRadius={50} />
              ) : (
                <>
                  <div className="title-1">
                    {loading ? (
                      <Skeleton count={1} width={150} height={30} />
                    ) : (
                      "Network Health"
                    )}
                  </div>
                  <img src={CheckCircle} alt="img" />
                </>
              )}
            </div>
          </Col>
          <section className="mt-4 nodes">
            <h4 className="mb-3">Nodes: {nodes.length}</h4>
            <Row>
              {nodes.map((node, index) => (
                <Col key={index} md={6} lg={6} sm={12} xs={12} className="mb-4">
                  <NodeWidget
                    from={node.from}
                    nodePort={node.nodePort}
                    nodeIp={node.nodeIp}
                    alias={node.alias}
                    services={node.services}
                    uptime={uptime[index]?.Node_info?.Metrics?.uptime}
                    sqNo={index + 1}
                  />
                </Col>
              ))}
            </Row>
          </section>
          <Schema />

          <section className="nodes">
            <Txs />
          </section>

          <div className="d-flex align-items-center justify-content-center">
            <NavLink
              to="https://explorer.dev.trustgrid.com/txs/TrustGridDev/domain"
              target="_blank"
            >
              <span className="blockchain-ledger-wiz btn btn-outline-primary mt-3">
                TrustGrid Ledger
              </span>
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NetworkDashboard;
