const Widget = ({ borderColor, icon, color, value, title, filter, href }) => {
  return (
    <div
      className="col-lg-4 col-md-6 col-xs-12 col-sm-12  my-1 "
      onClick={filter}
    >
      <a href={href} target="_blank" className="text-decoration-none">
        <div
          className={`card shadow text-dark  bg-opacity-25 borders-${borderColor}`}
        >
          <div className="card-body">
            <h6 className="card-subtitle mb-2 text-center">
              <i className={`bi bi-${icon} text-${color} mx-2`}></i>
              {title}
            </h6>
            <hr />

            <h1
              className={`col text-${color} text-center mx-4 fw-bolder display-6`}
            >
              {value}
            </h1>
          </div>
        </div>
      </a>
    </div>
  );
};

export default Widget;
