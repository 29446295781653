import React, { useState } from "react";
import { toast } from "react-toastify";
import { Modal, Spinner } from "react-bootstrap";
import { toastSuccess, toastWarning } from "../Common/ToastAlert";
import { deleteNewsFeed } from "../../api/network";

const DeleteFeed = ({
  show,
  onHide,
  data,
  setOpenDeleteModal,
  refetchData,
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  // handle delete
  const handleDeleteFeed = async (id) => {
    try {
      setLoading(true);
      const onDelete = await deleteNewsFeed(id);
      toast.success("Feed Deleted Successfully", toastSuccess());
      setLoading(false);
      setOpenDeleteModal(false);
      refetchData();
    } catch (err) {
      setError(err);
      toast.warning(
        "Hmm. Something doesn't seem right. Try refreshing or logging in again.",
        toastWarning()
      );
      setLoading(false);
    }
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="md"
      className="dsm-modal-popup"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Delete Feed?
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Are you sure you want to delete this feed?</p>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="dsm-btn-secondary" onClick={onHide}>
          Cancel
        </button>

        <button
          type="button"
          className="dsm-btn-danger"
          onClick={() => handleDeleteFeed(data.id)}
        >
          {loading ? (
            <Spinner animation="border" size="sm" variant="light" />
          ) : (
            "Delete"
          )}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteFeed;
