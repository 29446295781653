import React, { useState, useEffect } from "react";
import { Spinner, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import Header from "../Header/Header";
import AddNewIssuerModal from "./AddNewIssuer";
import { getAllIssuers } from "../../api/network";
import EmptyScreen from "../Common/EmptyScreen";
import NoResultImage from "../../assets/images/no-result.svg";
import NoDataImage from "../../assets/images/no-added-data.svg";
import CopyIcon from "../../assets/images/copy.svg";
import { formatDate } from "../../util/inputvalidations";
import { toastWarning, toastError, toastSuccess } from "../Common/ToastAlert";

const Issuer = () => {
  const navigate = useNavigate();
  const networkId = localStorage.getItem("networkId");
  const [openModal, setOpenModal] = useState(false);
  const [issuers, setIssuers] = useState([]);
  const [searchItem, setSearchItem] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchIssuerData = async () => {
    try {
      setLoading(true);

      const issuersCount = await getAllIssuers(networkId);
      const res = issuersCount.data.issuers;

      setIssuers(res);
      setFilteredData(res);
      setLoading(false);
    } catch (err) {
      setError(err);
      toast.warning(
        "Hmm. Something doesn't seem right. Try refreshing or logging in again.",
        toastWarning()
      );
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchIssuerData();
  }, []);

  const handleSearch = (query) => {
    setSearchItem(query);
    const filtered = issuers.filter(
      (organization) =>
        organization.orgName &&
        organization.orgName.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredData(filtered);
  };

  // copy DID
  const handleCopy = (id) => {
    navigator.clipboard.writeText(id).then(
      (id) => {
        toast.success("DID copied successfully", toastSuccess());
      },
      (err) => {
        toast.error("Oops! Try Again Later." + err, toastError());
      }
    );
  };

  const handleClickDetails = (data) => {
    navigate("/issuers/details", { state: { data: data } });
  };
  // No results content
  const noResultContent = {
    heading: "Sorry! No Results Found.",
    subHeading: [
      "We couldn’t find what you were looking for. Please try other ways.",
    ],
  };
  const noAddedContent = {
    heading: "No Issuer added yet",
    subHeading: ["Send Invitation to Issuer Organization"],
  };

  const sortedIssuer = filteredData.sort(
    (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
  );

  const tooltipContent = ` 
            The Issuer is a trusted entity within the self-sovereign identity
            (SSI) ecosystem responsible for attesting verifiable credentials
            (VCs) that represent the achievements, certifications, or attributes
            of holders.
                        `;
  const tooltipContentTwo = ` 
            The Issuer's primary focus is on ensuring the accuracy
            and verifiability of these VCs. This involves defining credential
            schemas and credential definitions that specify the data structure
            and type of information encapsulated within the VCs, such as
            diplomas or KYC verifications.
                        `;
  const tooltipContentThree = ` 
            After thoroughly verifying the holder's information, the Issuer
            attests the VCs in accordance with these predefined schemas,
            digitally signing them to provide cryptographic proof of
            authenticity and integrity.
            `;
  const tooltipContentFour = `             By ensuring the precision and validity
            of the attested credentials, the Issuer upholds a reputation of
            trustworthiness, enabling verifiers to reliably authenticate and
            trust the information contained within the VCs.
            `;

  return (
    <>
      {openModal && (
        <AddNewIssuerModal
          show={openModal}
          handleClose={() => setOpenModal(false)}
          refetchData={fetchIssuerData}
        />
      )}

      <div className="right-container">
        <div className="main-header">
          <Header
            title="Issuers"
            infoTooltip={true}
            tooltipContent={tooltipContent}
            tooltipContentTwo={tooltipContentTwo}
            tooltipContentThree={tooltipContentThree}
            tooltipContentFour={tooltipContentFour}
            tooltipPlacement="right"
          />
        </div>
        <div className="inner-body-container">
          <div className="requests-container">
            <div className="request-header">
              <div className="search-bar-box issuer-search-wiz">
                <input
                  type="search"
                  className="search-bar"
                  placeholder="Search Organization"
                  id="search-text-box"
                  value={searchItem}
                  onChange={(e) => handleSearch(e.target.value)}
                />
              </div>
              <div className="custom-select d-flex align-items-center justify-content-between add-issuer-wiz">
                <div className="select-box">
                  <button
                    className="dsm-btn-light mx-1"
                    onClick={fetchIssuerData}
                  >
                    <i class="bi bi-arrow-counterclockwise fw-bolder "></i>
                  </button>
                  <button
                    type="button"
                    className="dsm-btn-primary"
                    onClick={() => setOpenModal(true)}
                  >
                    Add +
                  </button>
                </div>
              </div>
            </div>
            <div className="requests-body">
              <Table borderless className="requests-table submit-proof-table">
                <thead>
                  <tr>
                    <th>Organization Name</th>
                    <th>Email ID</th>
                    <th>Decentralised Identity</th>
                    <th>Date</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr className="empty-screen-tr">
                      <td colSpan={5} rowSpan={8}>
                        <div className="mt-4 d-flex align-items-center justify-content-center">
                          <Spinner animation="border" variant="primary" />
                        </div>
                      </td>
                    </tr>
                  ) : issuers?.length === 0 ? (
                    <tr className="empty-screen-tr">
                      <td colSpan={5}>
                        <EmptyScreen
                          content={noAddedContent}
                          emptyScreenImage={NoDataImage}
                        />
                      </td>
                    </tr>
                  ) : (
                    <>
                      {sortedIssuer?.length === 0 ? (
                        <tr className="empty-screen-tr">
                          <td colSpan={5}>
                            <EmptyScreen
                              content={noResultContent}
                              emptyScreenImage={NoResultImage}
                            />
                          </td>
                        </tr>
                      ) : (
                        sortedIssuer?.map((organization) => (
                          <tr key={organization?.id}>
                            <td
                              onClick={() => handleClickDetails(organization)}
                            >
                              <span
                                style={{
                                  color: "#4398CD",
                                  cursor: "pointer",
                                  textDecoration: "underline",
                                }}
                              >
                                {organization?.orgName}
                              </span>
                            </td>
                            <td>{organization?.userName}</td>
                            {/* <td>
                              {organization?.issuerDid
                                ? organization?.issuerDid
                                : "-"}
                            </td> */}
                            <td>
                              {organization?.issuerDid ? (
                                <>
                                  <img
                                    className="img-fluid copy-icon"
                                    src={CopyIcon}
                                    alt="copy"
                                    onClick={() =>
                                      handleCopy(organization?.issuerDid)
                                    }
                                  />
                                  <span>{organization?.issuerDid}</span>
                                </>
                              ) : (
                                "-"
                              )}
                            </td>
                            <td style={{ textAlign: "left" }}>
                              {formatDate(organization?.updatedAt)}
                            </td>
                            <td>
                              <span
                                className={
                                  organization?.active === true
                                    ? "success-badge"
                                    : "pending-badge"
                                }
                              >
                                {organization?.active === true
                                  ? "Active"
                                  : "Pending"}
                              </span>
                            </td>
                          </tr>
                        ))
                      )}
                    </>
                  )}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Issuer;
