import React from "react";
import ReactDOM from "react-dom/client";
import { ToastContainer } from "react-toastify";
import App from "./App";
import { AuthProvider } from "./context/AuthContext";
import UserService from "./keycloak/UserService";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-icons/font/bootstrap-icons.css";


const renderApp = () => {
  ReactDOM.createRoot(document.getElementById("root")).render(
    <React.StrictMode>
      <AuthProvider>
        <ToastContainer
          className="dsm__Toastify__toast-container"
          position="top-center"
          hideProgressBar
          newestOnTop
          closeOnClick={false}
          autoClose={3000}
          limit={1}
        />
        <App />
      </AuthProvider>
    </React.StrictMode>
  );
};

UserService.initKeycloak(renderApp);
