import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import {
  toastError,
  toastSuccess,
  toastWarning,
} from "../../Common/ToastAlert";

import { AdminContext } from "../../../context/AdminContext";
import { addAdminUsers, getNetworkByUserId } from "../../../api/admin";
import { Spinner } from "react-bootstrap";
import customStyles from "../../../assets/sass/Common/selectStyles";
import TextField from "../../Common/TextField";

const InviteUser = ({ show, handleClose, refetchData }) => {
  const { addAdmin, updateAdminData, userId } = useContext(AdminContext);
  // const networkId = localStorage.getItem("networkId");
  const [selectedOption, setSelectedOption] = useState(null);
  const [network, setNetwork] = useState([]);
  const [loading, setLoading] = useState();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
  });

  const errorMessage = {
    selectOption: {
      required: "Please select a Consortium",
    },
    email: {
      required: "Email ID is required",
      invalid: "Enter a valid Email ID",
    },
  };

  const options =
    network?.map((network) => {
      return {
        value: network.name,
        label: network.name,
        id: network.id,
      };
    }) || [];

  // get all network by user Id
  const fetchAllNetworkData = async () => {
    if (userId) {
      try {
        const getNetwork = await getNetworkByUserId({ userId: userId });
        const res = getNetwork.data.result;
        setNetwork(res);
      } catch (error) {
        toast.warning(
          "Hmm. Something doesn't seem right. Try refreshing or logging in again.",
          toastWarning()
        );
      }
    }
  };

  useEffect(() => {
    if (userId) {
      fetchAllNetworkData();
    }
  }, [userId]);

  // handle select change
  const handleSelectChange = (option) => {
    setSelectedOption(option);
  };
  // send invite
  const sendInvitation = async (data) => {
    updateAdminData({
      email: data.email,
      networkId: selectedOption.id,
    });
    setLoading(true);
    try {
      const onCreate = await addAdminUsers(addAdmin);
      setLoading(false);
      if (onCreate.status === 200) {
        toast.success("Invite sent successfully", toastSuccess());
        handleClose();
        refetchData();
      } else {
        toast.error("Failed to invite", toastError());
        handleClose();
      }
    } catch (error) {
      setLoading(false);
      const errors = error.response
        ? "User does not exist on TrustGrid platform. Please create an account in the Sign-Up page by clicking the link below: https://ncc.dev.trustgrid.com/"
        : "There was an error. Please try again";
      toast.warning(errors, toastWarning());
      handleClose();
    }
    setLoading(false);
  };

  return (
    <>
      <Modal
        onHide={handleClose}
        show={show}
        size="Md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="dsm-modal-popup"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Invite User
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="mb-2">
            Add email addresses of users you would like to give permission to
            manage consortium
          </p>
          <div className="mb-3 w-100 textfield-container">
            <label htmlFor="selectConsortium" className="inputbox-label">
              Select Consortium
            </label>
            <Controller
              name="selectOption"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  styles={customStyles(!!errors.selectOption)}
                  closeMenuOnSelect={true}
                  options={options}
                  onChange={(option) => {
                    field.onChange(option);
                    handleSelectChange(option);
                  }}
                  placeholder="Select Consortium"
                />
              )}
              rules={{
                required: errorMessage.selectOption.required,
              }}
            />
            {errors.selectOption && (
              <small style={{ color: "#da1414", fontSize: 12 }}>
                {errors.selectOption.message}
              </small>
            )}
          </div>
          <div className="d-flex gap-1 align-items-end">
            <Controller
              control={control}
              name="email"
              render={({ field: { onChange, value, onBlur } }) => (
                <TextField
                  id="email"
                  label="Email ID"
                  type="email"
                  placeholder="Enter Email ID"
                  onChange={onChange}
                  value={value?.toLowerCase() ?? ""}
                  onBlur={onBlur}
                  error={errors?.email?.message}
                />
              )}
              rules={{
                required: errorMessage.email.required,
                pattern: {
                  value: /^[a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                  message: errorMessage.email.invalid,
                },
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="dsm-btn-secondary"
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            type="button"
            className="dsm-btn-primary"
            onClick={handleSubmit(sendInvitation)}
          >
            {loading ? (
              <Spinner animation="border" size="sm" variant="primary" />
            ) : (
              "Send Invite"
            )}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default InviteUser;
