import React, { useContext, useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Col, Row, Spinner, Tab, Tabs, ProgressBar } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { createNetwork } from "../../../../../api/admin";
import ChooseConsortiumImage from "../../../../../assets/images/create-consortium/membership.svg";
import {
  toastSuccess,
  toastError,
  toastWarning,
} from "../../../../Common/ToastAlert";
import { AdminContext } from "../../../../../context/AdminContext";

const ChooseRegion = ({ setCurrentStep }) => {
  const navigate = useNavigate();
  const { addNetwork } = useContext(AdminContext);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [progress, setProgress] = useState(0);
  const [stepMessage, setStepMessage] = useState("");

  const stepMessages = [
    "Initializing consortium creation...",
    "Configuring network parameters...",
    "Setting up blockchain infrastructure...",
    "Establishing node connections...",
    "Implementing security protocols...",
    "Configuring smart contracts...",
    "Setting up data storage...",
    "Initializing consensus mechanism...",
    "Configuring user permissions...",
    "Finalizing consortium setup..."
  ];

  useEffect(() => {
    let interval;
    if (loading) {
      interval = setInterval(() => {
        setProgress((prevProgress) => {
          if (prevProgress >= 100) {
            clearInterval(interval);
            return 100;
          }
          return prevProgress + 10;
        });
        setStepMessage(stepMessages[Math.floor(progress / 10)]);
      }, 10000);
    }
    return () => clearInterval(interval);
  }, [loading, progress]);

  const createNewConsortium = async () => {
    setLoading(true);
    setProgress(0);
    try {
      const onCreate = await createNetwork(addNetwork);
      if (onCreate.status === 201) {
        setLoading(false);
        toast.success("Consortium created successfully", toastSuccess());
        navigate("/home");
      } else {
        setLoading(false);
        toast.error("Failed to create Network", toastError());
      }
    } catch (error) {
      setLoading(false);
      setError(error.message);
      toast.warning(
        "Consortium already exists. Please enter a different consortium name.",
        toastWarning()
      );
    }
  };


  // temp choose option
  const dataChooseRegrion = [
    {
      title: "US West (Azure)",
      id: 0,
      value: "US West (Azure)",
    },
    {
      title: "US East Ohio (AWS)",
      id: 1,
      value: "US East Ohio (AWS)",
    },
    {
      title: "Europe West (Azure)",
      id: 2,
      value: "Europe West (Azure)",
    },
    {
      title: "Europe UK (AWS )",
      id: 3,
      value: "Europe UK (AWS )",
    },
    {
      title: "Southeast Asia (Azure)",
      id: 4,
      value: "Southeast Asia (Azure)",
    },
    {
      title: "Asia Pacific Singapore (AWS)",
      id: 5,
      value: "Asia Pacific Singapore (AWS)",
    },
    {
      title: "Asia Pacific Australia (AWS)",
      id: 6,
      value: "Asia Pacific Australia (AWS)",
    },
    {
      title: "Asia Pacific (India) (AWS)",
      id: 7,
      value: "Asia Pacific (India) (AWS)",
    },
    {
      title: "UAE Central (Azure)",
      id: 8,
      value: "UAE Central (Azure)",
    },
    {
      title: "Africa (Cape Town)(AWS)",
      id: 9,
      value: "Africa (Cape Town)(AWS)",
    },
    {
      title: "US West 2 (Azure)",
      id: 10,
      value: "US West 2 (Azure)",
    },
    {
      title: "US West Central (Azure)",
      id: 11,
      value: "US West Central (Azure)",
    },
    {
      title: "US Central (Azure)",
      id: 12,
      value: "US Central (Azure)",
    },
    {
      title: "US East (Azure)",
      id: 13,
      value: "US East (Azure)",
    },
    {
      title: "US West Oregon (AWS)",
      id: 14,
      value: "US West Oregon (AWS)",
    },
  ];

  return (
    <div className="consortium-details">
      <div className="description">
        <Row>
          <Col xl={6} lg={6} md={12} sm={12}>
            <img src={ChooseConsortiumImage} alt="" class="img-fluid" />
          </Col>
          <Col xl={6} lg={6} md={12} sm={12}>
            <p>
              A consortium is a private blockchain network and is hosted within
              a specific region of your choice, utilizing nodes to maintain
              network integrity and performance. The Node architecture provides
              high availability and redundancy through multiple isolated data
              centers, ensuring robust performance and GDPR compliance.
            </p>
            <p>
              Region selection depends on where your organization does business
              and where most of your end users reside to ensure transaction and
              data sovereignty. Choose the region closest to your location for
              better latency, performance, and user experience.
            </p>
          </Col>
        </Row>
      </div>
      <section className="choose-region">
        <Tabs defaultActiveKey="live" transition={false} className="mb-3">
          <Tab eventKey="live" title="Live Region">
            <p>
              Regions are pre-provisioned, and consortium will be provisioned in
              real time.
            </p>
            <div className="choose-region-list">
              <ul>
                <li className="selected-region-item">
                  <p>
                    <input className="dsm-radio-btn" type="radio" checked />
                    south-east-asia
                  </p>
                </li>
              </ul>
            </div>
          </Tab>
          <Tab eventKey="demand" title="On-Demand Region">
            <p>
              Region will be provisioned on-demand, and it can take up to a few
              hours for the consortium to be ready.
            </p>
            <div className="choose-region-list">
              <ul>
                {dataChooseRegrion.map((item) => {
                  const { id, title } = item;
                  return (
                    <li key={id} className="disabled-region-item">
                      <p>
                        <input
                          className="dsm-radio-btn"
                          type="radio"
                          id={id}
                          value={id}
                          disabled
                        />
                        {title}
                      </p>
                    </li>
                  );
                })}
              </ul>
            </div>
          </Tab>
        </Tabs>
      </section>
      <div className="input-details">
        <Row className="mb-2 justify-content-md-center">
          <Col lg={2} xl={2} md={2}>
            <button
              type="button"
              className="dsm-btn-secondary w-100 mb-2"
              onClick={() => {
                setCurrentStep(1);
              }}
              disabled={loading}
            >
              Back
            </button>
          </Col>
          <Col lg={2} xl={2} md={2}>
            <button
              type="button"
              className="dsm-btn-primary w-100"
              onClick={() => createNewConsortium()}
              disabled={loading}
            >
              {loading ? (
                <Spinner animation="border" size="sm" variant="light" />
              ) : (
                "Save"
              )}
            </button>
          </Col>
        </Row>
        {loading && (
          <Row className="mt-4">
            <Col>
              <ProgressBar now={progress} label={`${progress}%`} />
              <p className="text-center mt-2">{stepMessage}</p>
            </Col>
          </Row>
        )}
      </div>
    </div>
  );
};

export default ChooseRegion;