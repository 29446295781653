// import approve from "../../assets/images/approve.svg";
import approve from "../../assets/images/load.gif";


const Pending = () => {
  return (
    <div className="right-container d-flex justify-content-center align-items-center vh-100">
      <div>
        <div className="d-flex justify-content-center align-items-center mb-3  ">
          <img src={approve} alt="approve" className="img-fluid" />
        </div>
        <p className="display-6 text-center">
          Approval request was <span className="text-blue">submitted.</span>
        </p>
        <p className="text-secondary text-center mt-1 text-blue">
          Please note we monitor every profile carefully to maintain the integrity of the platform. <br/>You will recieve an email about the status of your profile soon. Hang
          tight!
        </p>
      </div>
    </div>
  );
};

export default Pending;
