import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import { Spinner, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { deleteNewsFeed, getAllNewsFeed } from "../../api/network";
import { toast } from "react-toastify";
import { toastSuccess, toastWarning } from "../Common/ToastAlert";
import { formatDate } from "../../util/inputvalidations";
import EmptyScreen from "../Common/EmptyScreen";
import NoResultImage from "../../assets/images/no-result.svg";
import NoDataImage from "../../assets/images/no-added-data.svg";
import EditPencil from "../../assets/images/pencil.svg";
import DeleteIcon from "../../assets/images/delete.svg";
import ThumbIcon from "../../assets/images/feed-thumb.svg";
import PublishFeed from "./PublishFeed";
import DeleteFeed from "./DeleteFeed";

const NewsFeedList = () => {
  const networkId = localStorage.getItem("networkId");
  const navigate = useNavigate();

  const [openModal, setOpenModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [feed, setFeed] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchItem, setSearchItem] = useState("");
  const [selectedFeed, setSelectedFeed] = useState(null);

  const fetchNewsFeedData = async () => {
    try {
      setLoading(true);

      const feedCount = await getAllNewsFeed(networkId);
      const res = feedCount.data.result;
      setFeed(res);
      setFilteredData(res);
      setLoading(false);
    } catch (err) {
      setError(err);
      toast.warning(
        "Hmm. Something doesn't seem right. Try refreshing or logging in again.",
        toastWarning()
      );
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchNewsFeedData();
  }, []);

  // handle search
  const handleSearch = (query) => {
    setSearchItem(query);
    const filtered = feed.filter(
      (feed) =>
        feed.hashtag && feed.hashtag.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredData(filtered);
  };

  // No results content
  const noResultContent = {
    heading: "Sorry! No Results Found.",
    subHeading: [
      "We couldn’t find what you were looking for. Please try other ways.",
    ],
  };
  const noAddedContent = {
    heading: "No News Feed added yet",
    subHeading: ["Create a news feed for all the Updates"],
  };

  const sortedFeeds = filteredData.sort(
    (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
  );

  const handleOpenPublishModal = (feed) => {
    setSelectedFeed(feed);
    setOpenModal(true);
  };
  const handleOpenDeleteModal = (feed) => {
    setSelectedFeed(feed);
    setOpenDeleteModal(true);
  };

  const navigateToEdit = (data) => {
    navigate("edit-news-feed", { state: { data: data } });
  };

  return (
    <>
      {openModal && (
        <PublishFeed
          show={openModal}
          onHide={() => setOpenModal(false)}
          data={selectedFeed}
          setOpenModal={setOpenModal}
          refetchData={fetchNewsFeedData}
        />
      )}
      {openDeleteModal && (
        <DeleteFeed
          show={openDeleteModal}
          onHide={() => setOpenDeleteModal(false)}
          data={selectedFeed}
          setOpenDeleteModal={setOpenDeleteModal}
          refetchData={fetchNewsFeedData}
        />
      )}
      <div className="right-container">
        <div className="main-header">
          <Header title="News Feed" />
        </div>
        <div className="inner-body-container">
          {/* <ComingSoon /> */}
          <div className="requests-container">
            <div className="request-header">
              <div className="search-bar-box">
                <input
                  type="search"
                  className="search-bar"
                  placeholder="Search Tag"
                  id="search-text-box"
                  value={searchItem}
                  onChange={(e) => handleSearch(e.target.value)}
                />
              </div>
              <div className="custom-select d-flex align-items-center">
                <div className="select-box">
                  <button
                    type="button"
                    className="dsm-btn-primary"
                    onClick={() => navigate("create-news-feed")}
                  >
                    Create News Feed
                  </button>
                </div>
              </div>
            </div>
            <div className="requests-body">
              <Table borderless className="requests-table submit-proof-table">
                <thead>
                  <tr>
                    <th>Image</th>
                    <th>Title</th>
                    <th>Tags</th>
                    <th style={{ textAlign: "left" }}>Updated</th>
                    <th>Status</th>
                    <th style={{ textAlign: "center" }}>Preview</th>
                    <th style={{ textAlign: "right", paddingRight: 16 }}>
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr className="empty-screen-tr">
                      <td colSpan={7} rowSpan={8}>
                        <div className="mt-4 d-flex align-items-center justify-content-center">
                          <Spinner animation="border" variant="primary" />
                        </div>
                      </td>
                    </tr>
                  ) : feed?.length === 0 ? (
                    <tr className="empty-screen-tr">
                      <td colSpan={7}>
                        <EmptyScreen
                          content={noAddedContent}
                          emptyScreenImage={NoDataImage}
                        />
                      </td>
                    </tr>
                  ) : (
                    <>
                      {sortedFeeds?.length === 0 ? (
                        <tr className="empty-screen-tr">
                          <td colSpan={7}>
                            <EmptyScreen
                              content={noResultContent}
                              emptyScreenImage={NoResultImage}
                            />
                          </td>
                        </tr>
                      ) : (
                        filteredData?.map((feed) => (
                          <tr key={feed?.id}>
                            <td className="feed-thumb">
                              <img
                                src={feed?.image || ThumbIcon}
                                height={40}
                                width={40}
                              />
                            </td>
                            <td className="ellipsis-text">
                              <b>{feed?.title}</b>
                            </td>
                            <td>
                              <sapn className="muted-badge">
                                {feed?.hashtag}
                              </sapn>
                            </td>
                            <td style={{ textAlign: "left" }}>
                              {formatDate(feed?.updatedAt)}
                            </td>
                            <td>
                              {feed?.active === true ? (
                                <sapn className="success-badge">Published</sapn>
                              ) : (
                                <sapn className="pending-badge">
                                  UnPublished
                                </sapn>
                              )}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              <button
                                type="button"
                                className="btn-sm dsm-btn-secondary icon-btn"
                                onClick={() => handleOpenPublishModal(feed)}
                              >
                                Preview
                              </button>
                            </td>
                            <td
                              style={{
                                textAlign: "right",
                                paddingRight: 16,
                                display: "flex",
                                justifyContent: "end",
                              }}
                            >
                              {!feed?.active === true && (
                                <button
                                  type="button"
                                  className="dsm-btn-secondary icon-btn mx-2"
                                  onClick={() => navigateToEdit(feed)}
                                >
                                  <img src={EditPencil} alt="icon" />
                                </button>
                              )}
                              <button
                                type="button"
                                className="dsm-btn-danger-light icon-btn"
                                onClick={() => handleOpenDeleteModal(feed)}
                              >
                                <img src={DeleteIcon} alt="icon" />
                              </button>
                            </td>
                          </tr>
                        ))
                      )}
                    </>
                  )}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewsFeedList;
