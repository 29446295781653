import { useState } from "react";

import { useNavigate } from "react-router-dom";

const RecoveryPhase = ({ setLoginProcess }) => {
  const [keys, setKeys] = useState([
    "orange",
    "lounge",
    "swim",
    "lagoon",
    "coffee",
    "bridge",
    "trees",
    "home",
    "city",
    "enrich",
    "shirt",
    "food",
  ]);
  const navigate = useNavigate();

  const handleLogin = (e) => {
    e.preventDefault();
    navigate("/dasboard");
  };
  return (
    <div className="login-form">
      <h5 className="text-secondary mb-1">Set Recovery Phase</h5>
      <p className="text-secondary ">
        This recovery phrase is a one-time setup to safegaurd your wallet.
        Please store it carefully for future wallet recovery.
      </p>

      <form onSubmit={handleLogin}>
        <div className="form-group">
          <label className="mb-1 text-white">Recovery Phrase</label>
          <div className="row">
            {keys.map((key, i) => (
              <div className="col-lg-3 p-1" key={i}>
                <div className="box">
                  <span>{key}</span>
                </div>
              </div>
            ))}
          </div>
        </div>

        <button type="submit">Continue</button>
      </form>
    </div>
  );
};

export default RecoveryPhase;
