import React from "react";
import Header from "../Header/Header";

const BlockchainLedger = () => {
  return (
    <div className="right-container">
      <div className="main-header">
        <Header title="Blockchain Ledger" />
      </div>
      <div className="inner-body-container">
        <div className="home-body">Content not added yet</div>
      </div>
    </div>
  );
};

export default BlockchainLedger;
