import React, { useContext, useState } from "react";
import Header from "../../../Header/Header";
import Stepper from "../../../Common/Stepper";
import ConsortiumDetails from "./Steps/ConsortiumDetails";
import ChooseRegion from "./Steps/ChooseRegion";


const CreateNewConsortium = () => {

  const [currentStep, setCurrentStep] = useState(1);
  const [stepName, setStepName] = useState([
    "Create New Consortium",
    "Choose Consortium Region",
  ]);

  return (
    <div className="right-container">
      <div className="main-header">
        <Header title="Creating New Consortium" />
      </div>
      <div className="inner-body-container">
        <div className="join-consortium-card">
          <Stepper currentStep={currentStep} stepName={stepName} />
          {currentStep === 1 ? (
            <ConsortiumDetails setCurrentStep={setCurrentStep} />
          ) : currentStep === 2 ? (
            <ChooseRegion setCurrentStep={setCurrentStep} />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default CreateNewConsortium;
