import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import TxsWidget from "../Common/TxsWidget";

import { getDomainTxs, getDomainTxsCount } from "../../api/network";

const Tsx = () => {
  const [txs, setTxs] = useState([]);
  const [txsCount, setTxsCount] = useState([]);

  useEffect(() => {
    const fetchclaimDefData = async () => {
      try {
        const response = await getDomainTxs();
        const data = response.data;

       

        const txsArray = data
          //   .reverse()
          .map((item) => {
            if (item.idata) {
              const txn = JSON.parse(item.idata.serialized.idata.json).txn;
              const txnMetadata = JSON.parse(
                item.idata.serialized.idata.json
              ).txnMetadata;

              const txnExpansion = item.idata.expansion.idata.txn;
              const meta = JSON.parse(item.idata.serialized.idata.json).txn
                .metadata;
              const txInfo = txn.data;

              if (txInfo.alias) {
                const alias = txInfo.alias;
                return {
                  from: meta.from,
                  txType: txnExpansion.typeName,
                  txSeqNo: txnMetadata.seqNo,
                  alias: txnMetadata.txnTime,
                  txnId: txnMetadata.txnId,
                };
              }
              return {
                from: meta.from,
                txType: txnExpansion.typeName,
                txSeqNo: txnMetadata.seqNo,
                alias: null,
              };
            }
            return null;
          });

        setTxs(txsArray);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    const fetchDomainTxsCount = async () => {
      try {
        const response = await getDomainTxsCount();
        setTxsCount(response.data.txCount);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchclaimDefData();
    fetchDomainTxsCount();
  }, []);
  return (
    <section className="mt-4 mb-2">
      <h4 className="mb-3">Last Transaction</h4>
      <Row>
        {txs.slice(0, 1).map((tx, index) => (
          <Col key={index} md={12} lg={12}>
            <TxsWidget
              from={tx.from}
              txType={tx.txType}
              txSeqNo={tx.txSeqNo}
              alias={tx.alias}
              txnId={tx.txnId}
            />
          </Col>
        ))}
      </Row>
    </section>
  );
};

export default Tsx;
