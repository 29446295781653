import React from "react";
import { formatDates } from "../../util/inputvalidations";
import TimeAgoText from "../Common/TimeAgo";
const TxsWidget = ({ txType, from, txSeqNo, alias, txnId, href }) => {
  const epoch = alias * 1000;
  let txnTimeIso8601 = epoch ? new Date(epoch).toISOString() : null;

  return (
    <div className=" nodes-card  border-start border-5 border-success-subtle">
      <a
        href={
          "https://explorer.dev.trustgrid.com/tx/TrustGridDev/domain/" + txSeqNo
        }
        target="_blank"
        className="text-decoration-none"
      >
        <div className="title-2 row mb-2">
          <span className="node-title col-2">Txn Id </span>
          <span className="node-subtext col-10"> {txnId}</span>
        </div>
        {alias !== null && (
          <div className="title-2 row mb-2">
            <span className="node-title col-2">Tx Date </span>
            <span className="node-subtext col-10"> {formatDates(alias)}</span>
          </div>
        )}

        <div className="title-2 row mb-2">
          <span className="node-title col-2">Tx Type </span>
          <span className="node-subtext col-10"> {txType}</span>
        </div>
        <div className="title-2 row mb-2">
          <span className="node-title col-2">Tx Sq No </span>
          <span className="node-subtext col-10"> {txSeqNo}</span>
        </div>

        <div className="title-2 row mb-1">
          <span className="node-title col-2"> Time Ago </span>
          <span className="node-subtext col-10">
            {" "}
            <TimeAgoText sinceEpoch={new Date(txnTimeIso8601)} />
          </span>
        </div>
      </a>
    </div>
  );
};

export default TxsWidget;
