import { Col, Row } from "react-bootstrap";
import { useAuth } from "../context/AuthContext";
import TrustGridLogo from "../assets/images/trustgrid-ncc-logo-center.svg";
import LandingImage from "../assets/images/landing-img.svg";
import ItemLogo1 from "../assets/images/welcome-1.svg";
import ItemLogo2 from "../assets/images/welcome-2.svg";
import ItemLogo3 from "../assets/images/welcome-3.svg";
import "../assets/sass/Common/_welcome.scss";
 
const Welcome = () => {
  const { handleLogin } = useAuth();
  return  (
    <div className="landing-page-main container-fluid p-0">
      <div className="body">
        <div className="brand-logo">
          <img
            src={TrustGridLogo}
            alt="logo"
            className="img-fluid"
            width={240}
            height={50}
          />
        </div>
        <Row className="mb-5">
          <Col
            xl={7}
            lg={9}
            md={12}
            sm={12}
            className="order-lg-1 order-md-2 order-sm-2 order-1"
          >
            <div className="welcome-content">
              <h4>
                Welcome to TrustGrid<sup className="sup">TM</sup> Network Control
                Center
              </h4>
              <p className="mb-4 text-justify">
                Welcome to Network Control Center, our state-of-the-art platform
                that empowers you to issue secure verifiable credentials on
                blockchain, enabling privacy-preserving transactions and ensuring
                seamless technological integration. Harnessing the power of Web3
                technologies, you can create and monitor consortiums to
                collaborate and onboard organizations like credential issuers and
                verifiers, catering to a wide range of sector-specific
                applications and services.
              </p>
              <button
                className="dsm-btn-primary w-50"
                onClick={handleLogin}
              >
                Login
              </button>
            </div>
          </Col>
          <Col
            xl={5}
            lg={3}
            md={12}
            sm={12}
            className="order-lg-2 order-md-1 order-sm-1 order-2"
          >
            <div className="decentral-logo">
              <img src={LandingImage} alt="logo" />
            </div>
          </Col>
        </Row>
      </div>
      <div className="footer">
        <div className="item">
          <div className="item-logo">
            <img src={ItemLogo1} alt="logo" className="img-fluid" />
          </div>
          <h4>Create and Manage Consortiums</h4>
          <p>
            Establish and manage consortiums to facilitate secure and
            collaborative interactions.
          </p>
        </div>{" "}
        <div className="item">
          <div className="item-logo">
            <img src={ItemLogo2} alt="logo" className="img-fluid" />
          </div>
          <h4>Onboard Issuers and Verifiers</h4>
          <p>
            Seamlessly add and manage issuers and verifiers to ensure a trusted
            and efficient credentialing process.
          </p>
        </div>{" "}
        <div className="item">
          <div className="item-logo">
            <img src={ItemLogo3} alt="logo" className="img-fluid" />
          </div>
          <h4>Immutable Blockchain Transactions</h4>
          <p>
            All transactions within the platform are securely recorded on the
            blockchain, ensuring transparency and immutability.
          </p>
        </div>
      </div>
    </div>
  );
}
 
export default Welcome;