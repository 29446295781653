import React from "react";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import Header from "../../Header/Header";
import "../../../assets/sass/_myaccount.scss";
import AccountSettingImage from "../../../assets/images/myaccount/account-setting.svg";

const Account = () => {
  const navigate = useNavigate();

  const tooltipContent =
    "Use this screen to add details to your profile, add users to manage the consortium, access service dashboard to view consortium details and many more things...";

  return (
    <React.Fragment>
      <div className="right-container">
        <div className="main-header">
          <Header
            title="My Account"
            infoTooltip={true}
            tooltipContent={tooltipContent}
            tooltipPlacement="right"
          />
        </div>
        <div className="inner-body-container">
          <div className="my-account-card">
            <div className="account-card-body">
              <Row>
                <Col lg={8} md={8} sm={6}>
                  <div className="heading">Account Settings</div>
                  <Row className="mb-4">
                    <Col lg={12} md={12} sm={12} xs={12}>
                      <span
                        className="link-text profile-wiz"
                        onClick={() => navigate("/account/profile")}
                      >
                        Profile
                      </span>
                    </Col>
                  </Row>
                  <div className="heading">Manage Services</div>
                  <Row className="mb-3">
                    <Col lg={6} md={6} sm={6} xs={6}>
                      <span
                        className="link-text service-dashboard-wiz"
                        onClick={() => navigate("/home")}
                      >
                        Service Dashboard
                      </span>
                    </Col>
                    <Col lg={6} md={6} sm={6} xs={6}>
                      <span
                        className="link-text mobile-application-wiz"
                        onClick={() => navigate("/account/mobile-application")}
                      >
                        Mobile Applications
                      </span>
                    </Col>
                  </Row>
                </Col>
                <Col lg={4} md={4} sm={6}>
                  <div className="img-card">
                    <img src={AccountSettingImage} alt="" class="img-fluid" />
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Account;
