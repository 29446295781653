import React, { useState, createContext, } from "react";

export const NetworkContext = createContext();

export const NetworkProvider = ({ children }) => {
  const [data, setData] = useState([]);
  const [networkId, setNetworkId] = useState();

  const [addFeed, setAddFeed] = useState({
    networkId: "",
    title: "",
    content: "",
    hashtag: "",
    image: "",
  });

  const updateFeedData = (data) => {
    setAddFeed((prev) => ({ ...prev, ...data }));
  };

  // useEffect(() => {
  //   setAddFeed((prev) => ({
  //     ...prev,
  //     networkId: networkId,
  //   }));
  // }, [networkId]);

  return (
    <NetworkContext.Provider
      value={{
        data,
        setData,
        networkId,
        setNetworkId,
        addFeed,
        setAddFeed,
        updateFeedData,
      }}
    >
      {children}
    </NetworkContext.Provider>
  );
};
