import React, { useCallback, useMemo } from "react";
import isHotkey from "is-hotkey";
import { Editable, withReact, useSlate, Slate } from "slate-react";
import {
  Editor,
  Transforms,
  createEditor,
  Element as SlateElement,
} from "slate";
import { withHistory } from "slate-history";

const HOTKEYS = {
  "mod+b": "bold",
  "mod+i": "italic",
  "mod+u": "underline",
  "mod+`": "code",
};

const LIST_TYPES = ["numbered-list", "bulleted-list"];
const TEXT_ALIGN_TYPES = ["left", "center", "right", "justify"];

const RichTextEditor = ({
  value,
  onChange,
  onBlur,
  error = "",
  label = "",
}) => {
  const renderElement = useCallback((props) => <Element {...props} />, []);
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);
  const editor = useMemo(() => withHistory(withReact(createEditor())), []);

  return (
    <div className="textfield-container">
      <label htmlFor="content" className="inputbox-label">
        {label}
      </label>
      <div
        style={{
          border: `1px solid ${error ? "#da1414" : "#E2E3E5"}`,
          borderRadius: "4px",
          marginBottom: "4px",
        }}
      >
        <Slate
          editor={editor}
          initialValue={value}
          onChange={onChange}
          onBlur={onBlur}
        >
          <div
            style={{
              marginBottom: "20px",
              display: "flex",
              gap: "10px",
              flexWrap: "wrap",
              padding: "6px",
            }}
          >
            <TextStyleButton format="bold" icon="B" />
            <TextStyleButton format="italic" icon="I" />
            <TextStyleButton format="underline" icon="U" />
            <TextStyleButton format="code" icon="</>" />
            <ToolButton format="heading-one" icon="H1" />
            <ToolButton format="heading-two" icon="H2" />
            <ToolButton format="block-quote" icon="❝" />
            <ToolButton format="numbered-list" icon="1." />
            <ToolButton format="bulleted-list" icon="•" />
            <ToolButton format="left" icon="⯇" />
            <ToolButton format="center" icon="⯀" />
            <ToolButton format="right" icon="⯈" />
            <ToolButton format="justify" icon="≡" />
            <ToolButton format="image" icon="🖼" iconText="Image" />
          </div>
          <Editable
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            placeholder="Enter text here…"
            spellCheck
            autoFocus
            onKeyDown={(event) => {
              for (const hotkey in HOTKEYS) {
                if (isHotkey(hotkey, event)) {
                  event.preventDefault();
                  const mark = HOTKEYS[hotkey];
                  toggleMark(editor, mark);
                }
              }
            }}
            style={{
              border: "none",
              padding: "0px 10px",
              outline: "none",
              boxShadow: "none",
            }}
          />
        </Slate>
      </div>
      {error && (
        <span style={{ color: "#da1414", fontSize: "12px" }}>{error}</span>
      )}
    </div>
  );
};

const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(
    editor,
    format,
    TEXT_ALIGN_TYPES.includes(format) ? "align" : "type"
  );
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      LIST_TYPES.includes(n.type) &&
      !TEXT_ALIGN_TYPES.includes(format),
    split: true,
  });

  const newProperties = TEXT_ALIGN_TYPES.includes(format)
    ? { align: isActive ? undefined : format }
    : { type: isActive ? "paragraph" : isList ? "list-item" : format };

  Transforms.setNodes(editor, newProperties);

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

const isBlockActive = (editor, format, blockType = "type") => {
  const { selection } = editor;
  if (!selection) return false;

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n) =>
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        n[blockType] === format,
    })
  );

  return !!match;
};

const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};

export const Element = ({ attributes, children, element }) => {
  const style = { textAlign: element.align || "left" };
  switch (element.type) {
    case "block-quote":
      return (
        <blockquote style={style} {...attributes}>
          {children}
        </blockquote>
      );
    case "bulleted-list":
      return (
        <ul style={style} {...attributes}>
          {children}
        </ul>
      );
    case "heading-one":
      return (
        <h1 style={style} {...attributes}>
          {children}
        </h1>
      );
    case "heading-two":
      return (
        <h2 style={style} {...attributes}>
          {children}
        </h2>
      );
    case "list-item":
      return (
        <li style={style} {...attributes}>
          {children}
        </li>
      );
    case "numbered-list":
      return (
        <ol style={style} {...attributes}>
          {children}
        </ol>
      );
    case "image":
      return (
        <div {...attributes}>
          <div style={{ textAlign: element.align }}>
            <img
              src={element.url}
              alt="Uploaded"
              style={{
                maxWidth: "200px",
                maxHeight: "200px",
                display: "block",
                position: "relative",
                zIndex: "999",
              }}
            />
          </div>
          {children}
        </div>
      );
    default:
      return (
        <p style={style} {...attributes}>
          {children}
        </p>
      );
  }
};

export const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.code) {
    children = <code>{children}</code>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  return <span {...attributes}>{children}</span>;
};

const ToolButton = ({ format, icon, iconText }) => {
  const editor = useSlate();
  const handleImageUpload = (event) => {
    event.preventDefault();
    uploadImage(editor);
  };
  return (
    <button
      type="button"
      style={{
        padding: "5px 10px",
        cursor: "pointer",
        border: isBlockActive(
          editor,
          format,
          TEXT_ALIGN_TYPES.includes(format) ? "align" : "type"
        )
          ? "2px solid #000"
          : "1px solid #ccc",
        backgroundColor: isBlockActive(
          editor,
          format,
          TEXT_ALIGN_TYPES.includes(format) ? "align" : "type"
        )
          ? "#ddd"
          : "transparent",
        borderRadius: "4px",
      }}
      onMouseDown={(event) => {
        event.preventDefault();
        event.stopPropagation();

        if (format === "image") {
          handleImageUpload(event);
        } else {
          toggleBlock(editor, format);
        }
      }}
    >
      {icon} {iconText}
    </button>
  );
};

export const uploadImage = async (editor) => {
  const input = document.createElement("input");
  input.type = "file";
  input.accept = "image/*";

  input.onchange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const url = reader.result;
        insertImage(editor, url);
      };
      reader.readAsDataURL(file);
    }
  };

  input.click();
};

export const insertImage = (editor, url, align = "left") => {
  const { selection } = editor;
  if (selection) {
    Transforms.insertNodes(editor, {
      type: "image",
      url,
      align,
      children: [{ text: "" }],
    });
  }
};

const TextStyleButton = ({ format, icon }) => {
  const editor = useSlate();
  return (
    <button
      type="button"
      style={{
        padding: "5px 10px",
        cursor: "pointer",
        border: isMarkActive(editor, format)
          ? "2px solid #000"
          : "1px solid #ccc",
        backgroundColor: isMarkActive(editor, format) ? "#ddd" : "transparent",
        borderRadius: "4px",
      }}
      onMouseDown={(event) => {
        event.preventDefault();
        event.stopPropagation();
        toggleMark(editor, format);
      }}
    >
      {icon}
    </button>
  );
};

export default RichTextEditor;
