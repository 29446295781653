import { useState } from "react";
import AuthenticationCode from "../../components/userLogin/authenticationCode";
import SetPassword from "../../components/userLogin/setPassword";
import RecoveryPhase from "../../components/userLogin/recoveryPhase";

const Login = () => {
  const [loginProcess, setLoginProcess] = useState(0);
  return (
    <>
      <div className="d-flex justify-content-center align-items-center vh-100 background">
      <div className="login-container">
            <div className="login-box">
                <h2 className="logo">TrustGrid<sup>TM</sup></h2>
        {loginProcess === 0 && <AuthenticationCode setLoginProcess={setLoginProcess} />}
        {loginProcess === 1 && <SetPassword setLoginProcess={setLoginProcess} />}
        {loginProcess === 2 && <RecoveryPhase />}


        </div>
        </div>

      </div>
    </>
  );
};

export default Login;
