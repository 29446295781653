import approve from "../../assets/images/load.gif";


const Rejected = () => {
  return (
    <div className="right-container d-flex justify-content-center align-items-center vh-100">
      <div>
        <div className="d-flex justify-content-center align-items-center mb-3  ">
          <img src={approve} alt="approve" className="img-fluid" />
        </div>
        <p className="display-6 text-center">
          Approval request was <span className="text-muted">rejected.</span>
        </p>
        <p className="text-secondary text-center mt-1 text-blue">
          Please note we monitor every profile carefully to maintain the integrity of the platform. <br/>Please contact <a href="https://trustgrid.com/contact-us/" target="_blank" rel="noreferrer" ><span className="text-blue">TrustGrid Support</span></a> for further details.
        </p>
      </div>
    </div>
  );
};

export default Rejected;
