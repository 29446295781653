import { useState } from "react";


const SetPassword = ({ setLoginProcess }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = (e) => {
    e.preventDefault();

    setLoginProcess(2);
  };
  return (
    <div className="login-form">
      <h5 className="text-secondary">Set Password</h5>
      <form onSubmit={handleLogin}>
      <div className="form-group">
          <label className="mb-1">Email ID</label>
          <input
          className="bg-dark"
            type="email"
            value={email}
            placeholder="x@x.com"
            onChange={(e) => setEmail(e.target.value)}
            disabled
          />
         
        </div>
      <div className="form-group">
          <label className="mb-1">Name</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
           
        </div>
        <div className="form-group">
          <label className="mb-1">Create Password</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label className="mb-1">Confirm Password</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <i class="bi bi-eye text-white"></i>
        </div>
        <button type="submit">Continue</button>
      </form>
    </div>
  );
};

export default SetPassword;
