export const validateEmail = (email) => {
  return email.match(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/);
};
export const formatDate = (dateString, customStyle = {}) => {
  const date = new Date(dateString);
  const day = date.toLocaleString("en-US", { weekday: "short" });
  const dayOfMonth = String(date.getDate()).padStart(2, "0");
  const month = date.toLocaleString("en-US", { month: "short" }); // "June"
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");
  const formattedDate = `${day}, ${dayOfMonth} ${month} ${year}`;
  const formattedTime = `${hours}:${minutes}:${seconds}`;

  const defaultStyle = {
    fontSize: "12px",
    marginBottom: 0,
  };
  const combinedStyle = { ...defaultStyle, ...customStyle };

  return (
    <>
      {}
      <p style={combinedStyle}>
        {formattedDate} {formattedTime}
      </p>
    </>
  );
};

export const isMobileDevice = () => {
  return /Mobi|Android/i.test(navigator.userAgent);
};

export const formatDates = (timestamp) => {
  const date = new Date(timestamp * 1000);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const year = String(date.getFullYear()).slice(-2);

  return `${day}/${month}/${year}`;
};

export function secondsToDhms(seconds) {
  seconds = Number(seconds);
  var d = Math.floor(seconds / (3600 * 24));
  var h = Math.floor((seconds % (3600 * 24)) / 3600);
  var m = Math.floor((seconds % 3600) / 60);
  var s = Math.floor(seconds % 60);

  var dDisplay = d > 0 ? d + (d === 1 ? " day, " : " days, ") : "";
  var hDisplay = h > 0 ? h + (h === 1 ? " hour, " : " hours, ") : "";
  var mDisplay = m > 0 ? m + (m === 1 ? " minute, " : " mins, ") : "";
  var sDisplay = s > 0 ? s + (s === 1 ? " second" : " secs") : "";
  return dDisplay + hDisplay + mDisplay + sDisplay;
}
