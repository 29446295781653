import React, { useEffect, useState } from "react";
import QRCode from "qrcode.react";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import { toastError, toastSuccess } from "../../Common/ToastAlert";
import CopyIcon from "../../../assets/images/copy.svg";

const ConsortiumQrModal = (props) => {
  const [QRdata, setQRdata] = useState({ networkId: "", networkName: "" });

  useEffect(() => {
    if (props?.data) {
      setQRdata({
        networkId: props.data.id || "",
        networkName: props.data.value || "",
      });
    }
  }, [props?.data]);

  const handleDownload = () => {
    const canvas = document.getElementById("qrCode");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    const downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `qrcode-${props?.data?.value}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const handleCopy = (id) => {
    navigator.clipboard.writeText(id).then(
      (id) => {
        toast.success("QR Code copied successfully", toastSuccess());
      },
      (err) => {
        toast.error("Oops! Try Again Later." + err, toastError());
      }
    );
  };
  const QRdataString = JSON.stringify(QRdata);
  const qrCodeString = btoa(QRdataString);

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="dsm-modal-popup"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Consortium QR Code
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-3">
        <div className="d-flex align-items-center flex-column justify-content-center p-2">
          <p>
            This QR has to be displayed on the external facing website for
            Client Organization.
          </p>
          <div className="qr-border-color border border-2 rounded-2  p-2 d-flex justify-content-center align-items-center">
            <QRCode
              value={QRdataString}
              size={230}
              id="qrCode"
              fgColor={"#4398cd"}
              bgColor={"#4398cd18"}
              includeMargin={true}
            />
          </div>
          <div className="w-60 mt-3 mb-4 qr-code-button-group">
            <button
              type="button"
              className="dsm-btn-primary w-100 mb-2"
              onClick={handleDownload}
            >
              Download QR
            </button>
            <button
              type="button"
              className="dsm-btn-secondary w-100"
              onClick={() => handleCopy(qrCodeString)}
            >
              <img className="img-fluid copy-icon" src={CopyIcon} alt="copy" />{" "}
              Copy QR
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ConsortiumQrModal;
