import { useState } from "react";


const AuthenticationCode = ({ setLoginProcess }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = (e) => {
    e.preventDefault();
      setLoginProcess(1);
  };
  return (
    <div className="login-form">
      <h5 className="text-secondary">User Authentication</h5>
      <form onSubmit={handleLogin}>
        <div className="form-group">
          <label className="mb-1">Email ID</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label className="mb-1">Authentication Code</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit">Continue</button>
      </form>
    </div>
  );
};

export default AuthenticationCode;
