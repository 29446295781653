import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_URL_NCC_BACKEND;

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
  async (config) => {
    const token = await getToken();
    if (token) {
      config.headers.Authorization = `${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

async function getToken() {
  return localStorage.getItem("authToken");
}

// export async function getAllNetworks() {
//   return await axios.get(`${BASE_URL}/network`);
// }

export async function getNetworkByUserId(data) {
  return await axiosInstance.post(
    `${BASE_URL}/network/getNetworkByUserId`,
    data
  );
}

export async function createNetwork(data) {
  console.log(data);
  return new Promise(async (resolve, reject) => {
    let vmCreationSuccessful = false;
    try {
      const response = await axiosInstance.post(`${BASE_URL}/network/createVM`, {
        name: data.name,
        region: data.regionId
      }, {
        responseType: 'text'
      });

      const lines = response.data.split('\n').filter(line => line.trim() !== '');
      for (const line of lines) {
        if (line.startsWith('data: {')) {
          const eventData = JSON.parse(line.slice(6));
          console.log('Received event:', eventData);
          if (eventData.text && eventData.text.includes('VM deployment completed successfully')) {
            vmCreationSuccessful = true;
            break;
          } else if (eventData.text && (
            eventData.text.includes('VM creation failed') ||
            eventData.text.includes('Failed to create VM') ||
            eventData.text.includes('Error creating VM')
          )) {
            throw new Error('VM creation failed');
          }
        }
      }

      if (vmCreationSuccessful) {
        console.log('VM created successfully. Creating network...');
        const networkResponse = await axiosInstance.post(`${BASE_URL}/network`, data);
        resolve(networkResponse);
      } else {
        throw new Error('VM creation process did not complete successfully');
      }
    } catch (error) {
      console.error('Error:', error.message);
      reject(error);
    }
  });
}

export async function getUserByEmail(data) {
  return await axiosInstance.post(`${BASE_URL}/user/getUserByEmail`, data);
}

export async function addAdminUsers(data) {
  return await axiosInstance.post(`${BASE_URL}/user/inviteUser`, data);
}
export async function getUser(userId) {
  return await axiosInstance.get(`${BASE_URL}/user/${userId}`);
}

export async function updateUser(data) {
  return await axiosInstance.post(`${BASE_URL}/user/updateUserByEmail/`, data);
}

export async function allNetworksUsers(networkId) {
  return await axiosInstance.get(`${BASE_URL}/network/user/${networkId}`);
}

export async function deleteNetworkUser(networkId, userId) {
  return await axiosInstance.delete(
    `${BASE_URL}/network/user/${networkId}/${userId}`
  );
}
