import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Col, Row, Spinner, Table } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../Header/Header";
import { formatDate } from "../../util/inputvalidations";
import arrowBack from "../../assets/images/arrow-left.svg";
import { toastWarning } from "../Common/ToastAlert";
import { IssuerUserServiceCount } from "../../api/network";
import ServiceViewModal from "./ServiceViewModal";
import EmptyScreen from "../Common/EmptyScreen";
import NoDataImage from "../../assets/images/no-added-data.svg";

const IssuerDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [services, setServices] = useState([]);
  const [serviceCount, setServiceCount] = useState(0);
  const { data } = location.state || {};
  const [openModal, setOpenModal] = useState(false);
  const [serviceData, setServicedata] = useState(null);

  const fetchIssuerServiceCount = async () => {
    try {
      setLoading(true);
      const issuerServiceCount = await IssuerUserServiceCount(data?.id);
      const res = issuerServiceCount.data.result;
      const newLength = res.length;
      setServices(res);
      setServiceCount(newLength);
      setLoading(false);
    } catch (err) {
      setError(err);
      toast.warning(
        "Hmm. Something doesn't seem right. Try refreshing or logging in again.",
        toastWarning()
      );
    }
  };

  useEffect(() => {
    fetchIssuerServiceCount();
  }, []);

  // No results content
  const noAddedContent = {
    heading: "No Published Claims are available",
  };

  const handleOpenService = (data) => {
    setServicedata(data);
    setOpenModal(true);
  };

  return (
    <React.Fragment>
      {openModal && (
        <ServiceViewModal
          show={openModal}
          handleClose={() => setOpenModal(false)}
          data={serviceData}
        />
      )}
      <div className="right-container">
        <div className="main-header">
          <Header title="Issuer Details" />
        </div>
        <div className="inner-body-container">
          <div className="mb-2">
            <span
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/issuers")}
            >
              <img src={arrowBack} alt="back" />
              Go Back
            </span>
          </div>
          <Row className="mb-4">
            <Col md={12} lg={12} sm={12} xs={12}>
              <div className="nodes-card">
                <div className="title-2 row mb-2">
                  <span className="node-title col-lg-3 col-md-3 col-sm-6">
                    Organization Name
                  </span>
                  <span className="node-subtext col-lg-3 col-md-3 col-sm-6">
                    {data.orgName}
                  </span>
                  <span className="node-title col-lg-3 col-md-3 col-sm-12">
                    Published Services
                  </span>
                  <span className="node-subtext col-lg-3 col-md-3 col-sm-12">
                    {serviceCount ? serviceCount : "0"}
                  </span>
                </div>
                <hr />
                <div className="title-2 row mb-2">
                  <span className="node-title col-lg-3 col-md-3 col-sm-12">
                    Decentralised Identity
                  </span>
                  <span className="node-subtext col-lg-9 col-md-9 col-sm-12">
                    {data.issuerDid ? data.issuerDid : "-"}
                  </span>
                </div>
                <hr />
                <div className="title-2 row mb-2">
                  <span className="node-title col-lg-3 col-md-3 col-sm-12">
                    Email ID{" "}
                  </span>
                  <span className="node-subtext col-lg-9 col-md-9 col-sm-12">
                    {data.userName}
                  </span>
                </div>{" "}
                <hr />
                <div className="title-2 row mb-2">
                  <span className="node-title col-lg-3 col-md-3 col-sm-6">
                    Status
                  </span>
                  <span className="node-subtext col-lg-3 col-md-3 col-sm-6">
                    <span
                      className={
                        data?.active === true
                          ? "success-badge"
                          : "pending-badge"
                      }
                    >
                      {data?.active === true ? "Active" : "Pending"}
                    </span>
                  </span>
                  <span className="node-title col-lg-2 col-md-2 col-sm-12">
                    Last Update
                  </span>
                  <span className="node-subtext col-lg-4 col-md-4 col-sm-12">
                    {formatDate(data?.updatedAt)}
                  </span>
                </div>
                <hr />
                <div className="title-2 row mb-2">
                  <span className="node-title col-lg-3 col-md-3 col-sm-12">
                    Network ID
                  </span>
                  <span className="node-subtext col-lg-3 col-md-3 col-sm-12">
                    {data.networkId ? data.networkId : "-"}
                  </span>
                  <span className="node-title col-lg-2 col-md-2 col-sm-12">
                    Website
                  </span>
                  <span className="node-subtext col-lg-4 col-md-4 col-sm-12">
                    {data.website ? data.website : "-"}
                  </span>
                </div>
                <hr />
                <div className="title-2 row mb-2">
                  <span className="node-title col-lg-3 col-md-3 col-sm-12">
                    Country Name{" "}
                  </span>
                  <span className="node-subtext col-lg-9 col-md-9 col-sm-12">
                    {data.country ? data.country : "-"}
                  </span>
                </div>
                <hr />
                <div className="title-2 row mb-2">
                  <span className="node-title col-lg-3 col-md-3 col-sm-12">
                    Address
                  </span>
                  <span className="node-subtext col-lg-9 col-md-9 col-sm-12">
                    {data.address ? data.address : "-"}
                  </span>
                </div>
              </div>
            </Col>
          </Row>
          <div className="requests-container">
            <div className="requests-body">
              <Table borderless className="requests-table submit-proof-table">
                <thead>
                  <tr>
                    <th>Claim Name</th>
                    <th>Updated At</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr className="empty-screen-tr">
                      <td colSpan={2} rowSpan={10}>
                        <div className="mt-4 d-flex align-items-center justify-content-center">
                          <Spinner animation="border" variant="primary" />
                        </div>
                      </td>
                    </tr>
                  ) : services?.length === 0 ? (
                    <tr className="empty-screen-tr">
                      <td colSpan={2}>
                        <EmptyScreen
                          content={noAddedContent}
                          emptyScreenImage={NoDataImage}
                        />
                      </td>
                    </tr>
                  ) : (
                    services?.map((service) => (
                      <tr key={service?.id}>
                        <td onClick={() => handleOpenService(service)}>
                          <span
                            style={{
                              color: "#4398CD",
                              cursor: "pointer",
                              textDecoration: "underline",
                            }}
                          >
                            {service?.name}
                          </span>
                        </td>
                        <td>{formatDate(service?.createdAt)}</td>
                      </tr>
                    ))
                  )}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default IssuerDetails;
