import React, { useEffect, useState } from "react";
import { Row } from "react-bootstrap";

import Widget from "../Common/Widget";

import { getSchemaTxs, getDomainSCHEMATxsCount, getDomainClainDefTxsCount, getDomainTxsCount } from "../../api/network";

const Schema = () => {
  const [schemas, setSchema] = useState([]);
  const [txsCount, setTxsCount] = useState([]);
  const [txsCountC, setTxsCountC] = useState([]);
  const [domainTxsCount, setDomainTxsCount] = useState([]);


  useEffect(() => {
    const fetchSchemaData = async () => {
      try {
        const response = await getSchemaTxs();
        const data = response.data;

        const schemaArray = data
          //   .reverse()
          .map((item) => {
            if (
              item.idata &&
              item.idata.serialized &&
              item.idata.serialized.idata
            ) {
              const txn = JSON.parse(item.idata.serialized.idata.json).txn;
              const meta = JSON.parse(item.idata.serialized.idata.json).txn
                .metadata;
              const schemaInfo = txn.data.data;
              return {
                from: meta.from,
                name: schemaInfo.name,
                version: schemaInfo.version,
              };
            }
            return null;
          })
          .filter((node) => node !== null);

        setSchema(schemaArray);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    const fetchTxsCount = async () => {
      try {
        const response = await getDomainSCHEMATxsCount();
        setTxsCount(response.data.txCount);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    const fetchTxsCountC = async () => {
      try {
        const response = await getDomainClainDefTxsCount();
        setTxsCountC(response.data.txCount);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    const fetchDomainTxsCount = async () => {
      try {
        const response = await getDomainTxsCount();
        setDomainTxsCount(response.data.txCount);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchTxsCountC();
    fetchTxsCount();
    fetchSchemaData();
    fetchDomainTxsCount();
  }, []);
  return (
    <section className="mt-4">
      {/* <h4 className="mb-3">Latest Schema: {txsCount}</h4> */}
      <Row className="widget">
        <Widget
          borderColor="g"
          icon="circle-fill"
          color="green"
          title="Schema Count"
          value={txsCount}
          href="https://explorer.dev.trustgrid.com/txs/TrustGridDev/domain?page=1&pageSize=50&filterTxNames=%5B%22SCHEMA%22%5D&sortFromRecent=true"
         
        />
        <Widget
          borderColor="g"
          icon="circle-fill"
          color="green"
          title="Claim Definition Count"
          value={txsCountC}
          href="https://explorer.dev.trustgrid.com/txs/TrustGridDev/domain?page=1&pageSize=50&filterTxNames=%5B%22CLAIM_DEF%22%5D&sortFromRecent=true"
        />
        <Widget
          borderColor="g"
          icon="circle-fill"
          color="green"
          title="All Transactions"
          value={domainTxsCount}
          href="https://explorer.dev.trustgrid.com/txs/TrustGridDev/domain?page=1&pageSize=50&filterTxNames=%5B%5D&sortFromRecent=true"
        />
        
       
      </Row>
    </section>
  );
};

export default Schema;
