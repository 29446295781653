// src/Tooltip.js
import React from "react";
import PropTypes from "prop-types";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import "../../assets/sass/Common/_customtooltip.scss";
import Info from "../../assets/images/info.svg";

const CustomTooltip = ({
  content,
  placement = "auto",
  infoTooltip = false,
  contentTwo,
  contentThree,
  contentFour,
}) => {
  if (!infoTooltip) return null;

  return (
    <>
      <OverlayTrigger
        className="dsm-overlay-container"
        placement={placement}
        overlay={
          <Tooltip id="button-tooltip" className="dsm-tooltip-light">
            <p>{content}</p>
            <p>{contentTwo}</p>
            <p>{contentThree}</p>
            <p>{contentFour}</p>
          </Tooltip>
        }
      >
        {({ ref, ...triggerHandler }) => (
          <img
            src={Info}
            alt="info icon"
            className="tooltip-info-icon"
            ref={ref}
            {...triggerHandler}
            style={{ cursor: "pointer" }}
          />
        )}
      </OverlayTrigger>
    </>
  );
};

CustomTooltip.propTypes = {
  content: PropTypes.string.isRequired,
  placement: PropTypes.oneOf(["auto", "top", "right", "bottom", "left"]),
  infoTooltip: PropTypes.bool,
};

CustomTooltip.defaultProps = {
  placement: "bottom",
  infoTooltip: false,
};

export default CustomTooltip;
