import { Navigate, createBrowserRouter } from "react-router-dom";
import NetworkRoot from "./root/NetworkRoot";
import Issuer from "./components/Issuer/Issuer";
import Verifier from "./components/Verifier/Verifier";
import BlockchainLedger from "./components/BlockchainLedger/BlockChainLedger";
// import NewsFeed from "./components/NewsFeed/NewsFeedList";
import Login from "./pages/userLogin";
import Notfound from "./components/Common/NotFound";
import NetworkDashboard from "./components/Dashboard/Dashboard";
import CreateNewFeed from "./components/NewsFeed/CreateFeed";

// tg-admin
import Root from "./root/Root";
import Dashboard from "./components/tgAdmin/Dashboard/Dashboard";
import Account from "./components/tgAdmin/MyAccount/Account";
import Documentation from "./components/tgAdmin/Documentation/Documentation";
import MobileApplication from "./components/tgAdmin/MyAccount/MobileApplication";
import Profile from "./components/tgAdmin/MyAccount/Profile";
import CreateNewConsortium from "./components/tgAdmin/Dashboard/AddNewConsortium/CreateNewConsortium";
import EditFeed from "./components/NewsFeed/EditFeed";
import NewsFeedList from "./components/NewsFeed/NewsFeedList";
import withUserStatus from "./components/permisions";
import IssuerDetails from "./components/Issuer/IssuerDetails";
import VerifierDetails from "./components/Verifier/VerifierDetails";
import ManageUsers from "./components/tgAdmin/ManageUsers/ManageUsers";

const ProtectedRoot = withUserStatus(Root);
const ProtectedNetworkRoot = withUserStatus(NetworkRoot);
const routes = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "/",
    element: <ProtectedRoot />,
    children: [
      {
        index: true,
        element: <Navigate to="/home" replace />,
      },
      {
        path: "/home",
        element: <Dashboard />,
      },
      {
        path: "/account",
        element: <Account />,
      },
      {
        path: "/documentation",
        element: <Documentation />,
      },
      {
        path: "/manage-users",
        element: <ManageUsers />,
      },
      {
        path: "/home/create-consortium",
        element: <CreateNewConsortium />,
      },
      {
        path: "/account/mobile-application",
        element: <MobileApplication />,
      },
      {
        path: "/account/profile",
        element: <Profile />,
      },
    ],
  },
  {
    path: "/",
    element: <ProtectedNetworkRoot />,
    children: [
      {
        path: "/dashboard",
        element: <NetworkDashboard />,
      },
      {
        path: "/issuers",
        element: <Issuer />,
      },
      {
        path: "/issuers/details",
        element: <IssuerDetails />,
      },
      {
        path: "/verifiers",
        element: <Verifier />,
      },
      {
        path: "/verifiers/details",
        element: <VerifierDetails />,
      },
      {
        path: "/blockchain-ledger",
        element: <BlockchainLedger />,
      },
      {
        path: "/news-feed",
        element: <NewsFeedList />,
      },
      {
        path: "/news-feed/create-news-feed",
        element: <CreateNewFeed />,
      },
      {
        path: "/news-feed/edit-news-feed",
        element: <EditFeed />,
      },
      // all tg-admin routes
    ],
  },

  {
    path: "*",
    element: <Notfound />,
  },
]);
export default routes;
