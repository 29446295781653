import React, { forwardRef } from "react";
const TextField = (
  {
    className = "",
    isViewMode = false,
    onChange,
    error = "",
    prefix = "",
    suffix = "",
    label = "",
    mandatory = false,
    wholeEvent = false,
    helperText = "",
    large = false,
    type = "",
    ...props
  },
  ref
) => {
  return !large ? (
    <div className={`${className} textfield-container`}>
      {label ? (
        <label className="inputbox-label">
          {label}
          {mandatory ? (
            <span className="inputbox-mandatory-mark">*</span>
          ) : null}
        </label>
      ) : null}
      <div
        className={`
      ${isViewMode ? "view-mode" : ""}`}
      >
        {isViewMode ? (
          <p className="view-mode-text">{props?.value || props?.placeholder}</p>
        ) : (
          <input
            type={type}
            ref={ref}
            className={`${"dsm-inputbox"} ${
              error ? "inputbox-error-border" : ""
            }`}
            onChange={(e) => onChange(wholeEvent ? e : e.target.value)}
            {...props}
            aria-label={label ? label : ""}
          />
        )}
      </div>
      {!error && <span className="helper-text">{helperText}</span>}
      {error && <span className="inputbox-error-msg">{error}</span>}
    </div>
  ) : (
    <div className={`${className} textfield-container`}>
      {label ? (
        <label className="inputbox-label">
          {label}
          {mandatory ? (
            <span className="inputbox-mandatory-mark">*</span>
          ) : null}
        </label>
      ) : null}
      <div
        className={` 
      ${isViewMode ? "view-mode" : ""}`}
      >
        {isViewMode ? (
          <p className="view-mode-text">{props?.value || props?.placeholder}</p>
        ) : (
          <input
            type={type}
            ref={ref}
            className={`${"inputbox-lg"} 
            ${error ? "inputbox-error-border" : ""}`}
            onChange={(e) => onChange(wholeEvent ? e : e.target.value)}
            {...props}
            aria-label={label ? label : ""}
          />
        )}
      </div>
      {!error && <span className="helper-text">{helperText}</span>}
      {error && <span className="inputbox-error-msg">{error}</span>}
    </div>
  );
};

export default forwardRef(TextField);
