import React, { useState, useMemo } from "react";
import { Slate, Editable, withReact } from "slate-react";
import { createEditor } from "slate";
import { Modal, Spinner } from "react-bootstrap";
import ThumbIcon from "../../assets/images/feed-thumb.svg";
import { formatDate } from "../../util/inputvalidations";
import { toastError, toastSuccess, toastWarning } from "../Common/ToastAlert";
import { publishNewsFeed } from "../../api/network";
import { toast } from "react-toastify";
import { Element, Leaf } from "./TextEditor";

const PublishFeed = ({ show, onHide, data, setOpenModal, refetchData }) => {
  const [loading, setLoading] = useState(false);

  const publishFeedData = async (data) => {
    if (!data?.id) return;
    const dataToPublish = {
      id: data.id,
    };
    try {
      setLoading(true);
      const onPublish = await publishNewsFeed(dataToPublish);
      if (onPublish.status === 200) {
        setLoading(false);
        toast.success("Feed has been published", toastSuccess());
        refetchData();
        setOpenModal(false);
      } else {
        setLoading(false);
        toast.error(
          "Hmm. Something doesn't seem right. Try refreshing or logging in again.",
          toastError()
        );
      }
    } catch (error) {
      setLoading(false);
      toast.warning(error.response.data.message, toastWarning());
    }
  };

  // const SlateRenderer = ({ content }) => {
  //   const editor = useMemo(() => withReact(createEditor()), []);
  //   const initialValue = Array.isArray(content)
  //     ? content
  //     : [{ type: "paragraph", children: [{ text: "" }] }];

  //   return (
  //     <Slate editor={editor} initialValue={initialValue} onChange={() => {}}>
  //       <Editable
  //         readOnly
  //         renderElement={(props) => <Element {...props} />}
  //         renderLeaf={(props) => <Leaf {...props} />}
  //       />
  //     </Slate>
  //   );
  // };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="md"
      className="dsm-modal-popup"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Publish News Feed
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <h4 className="mb-0 text-capitalize">{data?.title}</h4>
          <div className="d-flex align-items-center gap-2 mb-3">
            {formatDate(data?.updatedAt, { color: "#0076FC" })}
            <span className="muted-badge"> {data?.hashtag}</span>
          </div>
          <img
            src={data?.image || ThumbIcon}
            className="img-fluid"
            height={150}
            width={150}
            alt="thumb icon"
          />
          <div className="mt-2 text-justify">
            {/* <SlateRenderer content={JSON.parse(data?.content) || []} /> */}
            {data?.content}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        {!data?.active === true && (
          <>
            <button
              type="button"
              className="dsm-btn-danger-light"
              onClick={onHide}
            >
              Cancel
            </button>

            <button
              type="button"
              className="dsm-btn-primary"
              onClick={() => publishFeedData(data)}
            >
              {loading ? (
                <Spinner animation="border" size="sm" variant="light" />
              ) : (
                "Publish"
              )}
            </button>
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
};
export default PublishFeed;
