import React, { useContext, useEffect, useState } from "react";
import Select from "react-select";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import QRCodeImg from "../../../assets/images/create-consortium/QRCode.svg";
import ConsortiumIcon from "../../../assets/images/create-consortium/consortium-icon.svg";
import ConsortiumQrModal from "./ConsortiumQrModal";
import { getNetworkByUserId } from "../../../api/admin";
import { Spinner } from "react-bootstrap";
import { AdminContext } from "../../../context/AdminContext";
import { toast } from "react-toastify";
import { toastWarning } from "../../Common/ToastAlert";
import customStyles from "../../../assets/sass/Common/selectStyles";

const AllConsortiumList = () => {
  const navigate = useNavigate();
  const [network, setNetwork] = useState([]);
  const [selectedOption, setSelectedOption] = useState(
    network ? network[0] : []
  );
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const { userId } = useContext(AdminContext);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  const options =
    network?.map((network) => {
      return {
        value: network.name,
        label: network.name,
        id: network.id,
        regionId: network.regionId,
      };
    }) || [];

  const fetchAllNetworkData = async () => {
    try {
      setLoading(true);
      const getNetwork = await getNetworkByUserId({
        userId: userId,
      });
      const res = getNetwork.data.result;
      setNetwork(res);
      setSelectedOption(
        res[0]
          ? {
              value: res[0].name,
              label: res[0].name,
              id: res[0].id,
              regionId: res[0].regionId,
            }
          : null
      );
      setLoading(false);
    } catch (error) {
      toast.warning(error.response.data.message, toastWarning());
      toast.warning(
        "Hmm. Something doesn't seem right. Try refreshing or logging in again.",
        toastWarning()
      );
    }
  };
  useEffect(() => {
    fetchAllNetworkData();
  }, [userId]);

  const navigateToNetwork = (networkId, networkName) => {
    localStorage.setItem("networkId", networkId);
    localStorage.setItem("networkName", networkName);
    navigate("/dashboard", {
      state: { networkId: networkId, networkName: networkName },
    });
  };

  const handleNavigation = (networkId, networkName) => {
    navigateToNetwork(networkId, networkName);
  };

  return (
    <React.Fragment>
      {openModal && (
        <ConsortiumQrModal
          show={openModal}
          onHide={() => setOpenModal(false)}
          data={selectedOption}
        />
      )}
      <div className="consortium-list-page">
        <div className="sub-header">
          <div className="title">Consortiums</div>
          <div className="action-items">
            <div className="d-flex gap-2">
              <button
                className="dsm-btn-secondary action-icon consortium-qr-wiz"
                onClick={() => setOpenModal(true)}
              >
                <img src={QRCodeImg} alt="img" />
              </button>{" "}
              <div className="select-item select-consortium-wiz">
                <Select
                  styles={customStyles}
                  closeMenuOnSelect={true}
                  onChange={setSelectedOption}
                  options={options}
                  defaultValue={selectedOption}
                  placeholder={
                    selectedOption ? selectedOption.label : "Select Network"
                  }
                />
              </div>
            </div>
            <button
              class="dsm-btn-primary create-consortium-step"
              onClick={() => navigate("/home/create-consortium")}
            >
              Create New Consortium
            </button>
          </div>
        </div>
        <div className="body-content">
          {loading ? (
            <div className="loader">
              <Spinner animation="border" variant="primary" />
            </div>
          ) : (
            <>
              {selectedOption && (
                <div
                  className="consortium-card consortium-card-wiz"
                  key={network.id}
                  onClick={() =>
                    handleNavigation(selectedOption.id, selectedOption.label)
                  }
                >
                  <div className="card-header">
                    {loading ? (
                      <Skeleton height={28} width={60} />
                    ) : (
                      <span className="success-badge">Ready</span>
                    )}
                  </div>
                  <div className="card-body">
                    {loading ? (
                      <Skeleton height={55} width={52} />
                    ) : (
                      <img src={ConsortiumIcon} alt="img" />
                    )}

                    {loading ? (
                      <Skeleton height={27} width={144} />
                    ) : (
                      <p>{selectedOption?.label}</p>
                    )}
                  </div>
                  <div className="card-footer">
                    {loading ? (
                      <Skeleton height={21} width={200} />
                    ) : (
                      <>
                        Region:{" "}
                        <span>
                          {selectedOption.regionId
                            ? selectedOption.regionId
                            : "-"}
                        </span>
                      </>
                    )}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default AllConsortiumList;
