import React from "react";
import Header from "../../Header/Header";
import TrustGridOverview from "../../../assets/images/doc/trustgrid.svg";
import InfoIcon from "../../../assets/images/doc/info-fill.svg";
import { Link } from "react-router-dom";

const Documentation = () => {
  const handleScroll = (id) => {
    document.getElementById(id).scrollIntoView({ behavior: "smooth" });
  };
  return (
    <div className="right-container">
      <div className="main-header">
        <Header title="Documentation" />
      </div>
      <div className="inner-body-container">
        <div className="documentation-main">
          <section>
            <h1 className="title">
              Overview{" "}
              <Link
                to="https://wiki.trustgrid.com/"
                target="_blank"
                className="wiki-doc-wiz"
              >
                <button type="buttton" className="dsm-btn-secondary">
                  TrustGrid Wiki
                </button>
              </Link>
            </h1>
            <div className="description">
              TrustGrid<sup>TM</sup> offers a confidential computing platform
              for identity-based transactions. The platform serves as a trusted
              identity layer that enables organisations to build a decentralized
              identity network between your business partners, consumers and
              other agencies. TrustGrid<sup>TM</sup> provides a trusted network
              where identity transactions can be designed and executed with
              guaranteed levels of privacy and confidentiality.
            </div>
            <img src={TrustGridOverview} alt="img" class="img-fluid" />
          </section>{" "}
          <section>
            <div className="note-card">
              <div className="header">
                <img src={InfoIcon} alt="info" />
                Note
              </div>
              <div className="description">
                The following pages give a more in-depth description of
                TrustGrid<sup>TM</sup>
                platform and its relevant components :
              </div>
              <ul>
                <li>
                  <a href="#" onClick={() => handleScroll("consortium")}>
                    Consortium
                  </a>
                </li>
                <li>
                  <a href="#" onClick={() => handleScroll("member")}>
                    Member Portal
                  </a>
                </li>
                <li>
                  <a href="#" onClick={() => handleScroll("service")}>
                    Service Nodes
                  </a>
                </li>
                <li>
                  <a href="#" onClick={() => handleScroll("mobile")}>
                    Mobile Apps
                  </a>
                </li>
              </ul>
            </div>
          </section>
          <section id="consortium">
            <h1 className="title">Consortium</h1>
            <div className="description mb-3">
              In a modern digital economy a digital ecosystem is a consortium
              that involves multiple parties collaborating under
              technology-enforced rules. These ecosystems cannot rely solely on
              written contracts and policies, as they are inherently reactive.
              Instead, the consortium is governed by predefined policies agreed
              upon by all members.
            </div>
            <div className="description">
              Each consortium comprises of one or more members who are
              responsible for governance. In the TrustGrid network, nodes
              maintain network operations and integrity. Consortium members
              manage consortium governance and can add users to administer
              consortium activities. No sensitive data is stored on the chain or
              the network.
            </div>
          </section>{" "}
          <section id="member">
            <h1 className="title">Member Portal</h1>
            <div className="description mb-3">
              The Member Portal comprises of 3 distinct sections: the Network
              Control Center, the Issuer Center, and the Verifier Center,
              tailored for consortium utilization.
            </div>
            <div className="description mb-3">
              <ul>
                <li>
                  <b>1. Network Control Center</b>: Here the network
                  administrators can efficiently onboard issuers and verifiers
                  to the consortium.
                </li>
                <br></br>
                <li>
                  <b>2. Issuer Center</b>: This empowers issuers to generate and
                  publish new services, aligning verifiable credential
                  attributes with these services seamlessly.
                </li>{" "}
                <br></br>
                <li>
                  <b>3. Verifier Center</b>: Here the verifiers can craft
                  verifiable claims against services established by issuers
                  within the consortium. This functionality enables them to
                  solicit proof from holders for the credentials issued by the
                  respective issuers.
                </li>
              </ul>
            </div>
            <div className="description">
              Within the Verifier Center, verifiers can craft verifiable claims
              against services established by issuers within the consortium.
              This functionality enables them to solicit proof from holders for
              the credentials issued by the respective issuers.
            </div>
          </section>
          <section id="service">
            <h1 className="title">Service Nodes</h1>
            <div className="description mb-3">
              Service nodes are transactional end points that host ledgers and
              provides decentralized communication between all all parties in
              the consortium and users of the consortium. They provide QR codes
              or links for consortium discovery, sending OTPs, registering DIDs,
              and verifying transactions.
            </div>
            <div className="description">
              End users generate cryptographic key pairs on their devices
              through Biometric Binding on Hardware Root of Trust (HRoT) and
              registers their Decentralized Identifiers with the consortium
              using the provided registration links through the Service Nodes.
              Integrated mobile applications allow users to fetch a list of
              published services and register for such services based on their
              eligibility.
            </div>
          </section>{" "}
          <section id="mobile">
            <h1 className="title">Mobile Apps</h1>
            <div className="description mb-3">
              TrustGrid<sup>TM</sup> platform provides complementary
              applications ready to use with Platform. Users can download these
              applications from Apple Store and Google PlayStore.
            </div>
            <div className="description">
              TrustGrid application allows users to register their identity and
              get real-time updates on new subscriptions from consortium. Users
              can scan the consortium Digital QR code to discover and subscribe
              to the services.
            </div>
          </section>{" "}
        </div>
      </div>
    </div>
  );
};

export default Documentation;
