import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import Rejected from "./rejected";
import Pending from "./pending";
import { getUserByEmail } from "../../api/admin";
import UserService from "../../keycloak/UserService";
import { Spinner } from "react-bootstrap";

const withUserStatus = (WrappedComponent) => {
  return (props) => {
    const [status, setStatus] = useState(null);

    // add error handling 
    const fetchStatus = async () => {
      const email = UserService.getUsername()?.email;
      const response = await getUserByEmail({ email: email });
      const access = response.data.result.access;
      setStatus(access);
    };
    useEffect(() => {
      fetchStatus();
    }, []);

    if (status === null) {
      return (
        <div className="d-flex justify-content-center align-items-center vh-100">
          <div>
            <Spinner animation="border" variant="info" />
          </div>
        </div>
      );
    }

    if (status === "pending") {
      return <Pending />;
    }

    if (status === "rejected") {
      return <Rejected />;
    }

    if (status === "approved") {
      return <WrappedComponent {...props} />;
    }

    return <Navigate to="/" />;
  };
};

export default withUserStatus;
