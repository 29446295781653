import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Col, Row, Spinner } from "react-bootstrap";
import Header from "../Header/Header";
import { toastError, toastSuccess, toastWarning } from "../Common/ToastAlert";
import { createNewsFeed } from "../../api/network";
import { NetworkContext } from "../../context/NetworkContext";
import NotUploadImage from "../../assets/images/upload image.svg";
import TextField from "../Common/TextField";
import RichTextEditor from "./TextEditor";
import TextArea from "../Common/TextArea";

const CreateNewFeed = () => {
  const navigate = useNavigate();
  const networkId = localStorage.getItem("networkId");
  const { setAddFeed } = useContext(NetworkContext);
  const [selectedImage, setSelectedImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
    // defaultValues: {
    //   content: [
    //     {
    //       type: "paragraph",
    //       children: [{ text: "" }],
    //     },
    //   ],
    // },
  });

  const errorMessage = {
    title: {
      required: "Title is required",
      invalid: "Title should contain minimum one word",
      min: "Title should contain minimum of 3 characters",
    },
    hashtag: {
      required: "Tag is required",
      invalid: "Tag should not contain special characters",
      min: "Tag should contain minimum of 3 characters",
      max: "Tag should contain maximum of 30 characters",
    },
    content: {
      required: "Content is required",
      invalid: "Enter a valid content",
      min: "Content should contain minimum of 3 characters",
    },
  };

  // create new feed
  const createFeed = async (data) => {
    try {
      setLoading(true);
      const onCreate = await createNewsFeed({
        networkId: networkId,
        title: data.title,
        hashtag: data.hashtag,
        // content: JSON.stringify(data.content),
        content: data.content,
        image: selectedImage,
      });
      if (onCreate.status === 200) {
        setLoading(false);
        toast.success("Feed created successfully", toastSuccess());
        navigate("/news-feed");
        setAddFeed({
          networkId: networkId,
          title: "",
          hashtag: "",
          content: "",
          image: "",
        });
      } else {
        setLoading(false);
        toast.error("Failed to create Feed", toastError());
      }
    } catch (error) {
      setLoading(false);
      setError({ message: error.message });
      toast.warning(
        "Hmm. Something doesn't seem right. Try refreshing or logging in again.",
        toastWarning()
      );
    }
  };
  // Handle file change
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileType = file.type.toLowerCase();
      const validTypes = ["image/jpeg", "image/jpg", "image/png"];
      const maxSizeInByte = 1 * 1024 * 1024;

      if (file.size > maxSizeInByte) {
        setSelectedImage(null);
        setError((prevError) => ({
          ...prevError,
          selectedImage: "File size exceeds 1MB",
        }));
        return;
      }
      if (validTypes.includes(fileType)) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          setSelectedImage(reader.result);
        };
        setError((prevError) => ({ ...prevError, selectedImage: "" }));
      } else {
        setSelectedImage(null);
        setError((prevError) => ({
          ...prevError,
          selectedImage: "Only JPG, JPEG, and PNG files are allowed.",
        }));
      }
    }
  };

  return (
    <>
      <div className="right-container">
        <div className="main-header">
          <Header title="Create News Feed" />
        </div>
        <div className="inner-body-container">
          <div className="join-consortium-card">
            <form onSubmit={handleSubmit(createFeed)}>
              <Row>
                <Col lg={8}>
                  <Col lg={12} className="mb-3">
                    <Controller
                      control={control}
                      name="title"
                      render={({ field: { onChange, value, onBlur } }) => {
                        return (
                          <TextField
                            label="Title"
                            type="text"
                            placeholder="Enter First Name"
                            onChange={onChange}
                            value={value ?? ""}
                            onBlur={onBlur}
                            error={errors?.title?.message}
                          />
                        );
                      }}
                      rules={{
                        required: errorMessage.title.required,
                        pattern: {
                          value:
                            /^(?=(?:.*[A-Za-z]){3})[A-Za-z0-9 .,!?'"()\-:]+$/,
                          message: errorMessage.title.invalid,
                        },
                        minLength: {
                          value: 3,
                          message: errorMessage.title.min,
                        },
                      }}
                    />
                  </Col>
                  <Row className="mb-3">
                    <Col lg={6}>
                      <Controller
                        control={control}
                        name="hashtag"
                        render={({ field: { onChange, value, onBlur } }) => {
                          return (
                            <TextField
                              label="Tags"
                              type="text"
                              placeholder="Enter Tags here"
                              onChange={onChange}
                              value={value ?? ""}
                              onBlur={onBlur}
                              error={errors?.hashtag?.message}
                            />
                          );
                        }}
                        rules={{
                          required: errorMessage.hashtag.required,
                          pattern: {
                            value: /^(?=(?:.*[A-Za-z ]){3})[A-Za-z0-9 ]+$/,
                            message: errorMessage.hashtag.invalid,
                          },
                          minLength: {
                            value: 3,
                            message: errorMessage.hashtag.min,
                          },
                          maxLength: {
                            value: 30,
                            message: errorMessage.hashtag.max,
                          },
                        }}
                      />
                    </Col>
                    <Col lg={6}>
                      <div className="textfield-container">
                        <label className="inputbox-label">
                          Image Upload{" "}
                          <small style={{ color: "#1C1C1C", fontSize: 10 }}>
                            (max size 1 MB)
                          </small>
                        </label>
                        <input
                          type="file"
                          placeholder="image"
                          maxLength={40}
                          onChange={handleFileChange}
                          className={`dsm-inputbox  ${
                            error.selectedImage ? "inputbox-error-border" : ""
                          }`}
                        />
                        {error.selectedImage && (
                          <span className="inputbox-error-msg">
                            {error.selectedImage}
                          </span>
                        )}
                      </div>
                    </Col>
                  </Row>
                  {/* <Controller
                    control={control}
                    name="content"
                    render={({ field: { onChange, value, onBlur } }) => {
                      return (
                        <RichTextEditor
                          label="Content"
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value ?? ""}
                          error={errors?.content?.message}
                        />
                      );
                    }}
                    rules={{
                      required: "Content is required",
                      validate: (value) => {
                        const isEmpty = value?.every(
                          (node) => node.children[0].text.trim() === ""
                        );
                        return isEmpty ? "Content is required" : true;
                      },
                    }}
                  /> */}
                  <Controller
                    control={control}
                    name="content"
                    render={({ field: { onChange, value, onBlur } }) => {
                      return (
                        <TextArea
                          label="Content"
                          type="text"
                          row="5"
                          placeholder="Add content here"
                          onChange={onChange}
                          value={value ?? ""}
                          onBlur={onBlur}
                          error={errors?.content?.message}
                        />
                      );
                    }}
                    rules={{
                      required: errorMessage.content.required,
                      pattern: {
                        value: /^[\s\S]*[A-Za-z]+[\s\S]*$/,
                        message: errorMessage.content.invalid,
                      },
                      minLength: {
                        value: 3,
                        message: errorMessage.content.min,
                      },
                    }}
                  />
                </Col>
                <Col lg={4} md={12} sm={12}>
                  <div
                    className="d-flex align-items-center justify-content-center"
                    style={{ width: "100%", height: "100%" }}
                  >
                    <img
                      src={selectedImage ? selectedImage : NotUploadImage}
                      alt="img"
                      width={100}
                      height={100}
                    />
                  </div>
                </Col>

                <Col lg={12} md={12} sm={12}>
                  <div className="d-flex align-items-center gap-2 justify-content-end mt-5">
                    <button
                      type="button"
                      className="dsm-btn-secondary"
                      onClick={() => navigate(-1)}
                    >
                      Cancel
                    </button>
                    {loading ? (
                      <button
                        type="button"
                        className="dsm-btn-primary"
                        disabled
                      >
                        <Spinner animation="border" size="sm" variant="light" />
                      </button>
                    ) : (
                      <button type="submit" className="dsm-btn-primary">
                        Create Feed
                      </button>
                    )}
                  </div>
                </Col>
              </Row>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateNewFeed;
