import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_URL_NCC_BACKEND;
const BASE_URL_ISSUER_BACKEND = process.env.REACT_APP_BASE_URL_ISSUER_BACKEND;
const BASE_URL_VERIFIER_BACKEND =
  process.env.REACT_APP_BASE_URL_VERIFIER_BACKEND;
const BASE_URL_EXPLORER = process.env.REACT_APP_BASE_URL_EXPLORER;
const NETWORK_NAME = "TrustGridDev";
const BASE_NETWORK = process.env.REACT_APP_BASE_URL_NETWORK;

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
  async (config) => {
    const token = await getToken();
    if (token) {
      config.headers.Authorization = `${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

async function getToken() {
  return localStorage.getItem("authToken");
}

export async function getAllIssuersCount(networkId) {
  return await axiosInstance.post(`${BASE_URL}/issuer/count`, {
    networkId: networkId,
  });
}

export async function getAllIssuers(networkId) {
  return await axiosInstance.post(
    `${BASE_URL}/issuer/getAllIssuersByNetworkId`,
    {
      networkId: networkId,
    }
  );
}

export async function getAllVerifiersCount(networkId) {
  return await axiosInstance.post(`${BASE_URL}/verifier/count`, {
    networkId: networkId,
  });
}

export async function getAllVerifiers(networkId) {
  return await axiosInstance.post(
    `${BASE_URL}/verifier/getAllVerifiersByNetworkId`,
    {
      networkId: networkId,
    }
  );
}

export async function getAllServicesCount(networkId) {
  return await axiosInstance.post(`${BASE_URL}/service/count`, {
    networkId: networkId,
  });
}

export async function InviteIssuer(data) {
  return await axiosInstance.post(`${BASE_URL}/issuer/invite`, data);
}

export async function InviteVerifier(data) {
  return await axiosInstance.post(`${BASE_URL}/verifier/invite`, data);
}

export async function IssuerUserServiceCount(issuerId) {
  return await axiosInstance.get(`${BASE_URL}/issuer/stats/${issuerId}`);
}
export async function VerifierUserClaimCount(verifierId) {
  return await axiosInstance.get(
    `${BASE_URL_VERIFIER_BACKEND}/claim/getAllClaims/${verifierId}`
  );
}

// export async function VerifierUserClaimCount(claimId) {
//   return await axiosInstance.get(
//     `${REACT_APP_BASE_URL_VERIFIER_BACKEND}/claim/getAllClaims/${claimId}`
//   );
// }

// News Feed

export async function getAllNewsFeed(networkId) {
  return await axiosInstance.get(`${BASE_URL}/feed/list/${networkId}`);
}

export async function deleteNewsFeed(feedId) {
  return await axiosInstance.delete(`${BASE_URL}/feed/${feedId}`);
}

export async function createNewsFeed(data) {
  return await axiosInstance.post(`${BASE_URL}/feed/`, data);
}

export async function updateNewsFeed(data) {
  return await axiosInstance.post(`${BASE_URL}/feed/update`, data);
}

export async function publishNewsFeed(data) {
  return await axiosInstance.post(`${BASE_URL}/feed/publish`, data);
}

// Explorer API
// Todo to .env

export async function getDomainTxs(data) {
  return await axios.get(
    `${BASE_URL_EXPLORER}/api/networks/${NETWORK_NAME}/ledgers/domain/txs`
  );
}

export async function getDomainTxsCount(data) {
  return await axios.get(
    `${BASE_URL_EXPLORER}/api/networks/${NETWORK_NAME}/ledgers/domain/txs/stats/count`
  );
}

export async function getDomainSCHEMATxsCount(data) {
  return await axios.get(
    `${BASE_URL_EXPLORER}/api/networks/${NETWORK_NAME}/ledgers/domain/txs/stats/count?filterTxNames=["SCHEMA"]`
  );
}

export async function getDomainClainDefTxsCount(data) {
  return await axios.get(
    `${BASE_URL_EXPLORER}/api/networks/${NETWORK_NAME}/ledgers/domain/txs/stats/count?filterTxNames=["CLAIM_DEF"]`
  );
}

export async function getPoolTxs(data) {
  return await axios.get(
    `${BASE_URL_EXPLORER}/api/networks/${NETWORK_NAME}/ledgers/pool/txs`
  );
}

export async function getSchemaTxs(data) {
  return await axios.get(
    `${BASE_URL_EXPLORER}/api/networks/${NETWORK_NAME}/ledgers/domain/txs?filterTxNames=["SCHEMA"]`
  );
}

export async function getClaimDefTxs(data) {
  return await axios.get(
    `${BASE_URL_EXPLORER}/api/networks/${NETWORK_NAME}/ledgers/domain/txs?filterTxNames=["CLAIM_DEF"]`
  );
}

// Get all issuesd credential
export async function getIssuedCredentials() {
  return await axiosInstance.get(
    `${BASE_URL_ISSUER_BACKEND}/credential/getIssuedCredentials/`
  );
}

export async function getUptime(id) {
  return await axios.get(`${BASE_NETWORK}/status?validators=${id}`);
}
