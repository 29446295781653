import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Spinner, Table } from "react-bootstrap";
import Header from "../Header/Header";
import AddNewVerifierModal from "./AddNewVerifier";
import EmptyScreen from "../Common/EmptyScreen";
import NoResultImage from "../../assets/images/no-result.svg";
import NoDataImage from "../../assets/images/no-added-data.svg";
import CopyIcon from "../../assets/images/copy.svg";
import { getAllVerifiers } from "../../api/network";
import { formatDate } from "../../util/inputvalidations";
import { toastError, toastSuccess, toastWarning } from "../Common/ToastAlert";

const Verifier = () => {
  const navigate = useNavigate();
  // const {networkId} = useContext(NetworkContext);
  const networkId = localStorage.getItem("networkId");

  const [openModal, setOpenModal] = useState(false);
  const [verifier, setVerifier] = useState([]);
  const [searchItem, setSearchItem] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState();
  const [error, setError] = useState(null);

  const fecthVerifierData = async () => {
    try {
      setLoading(true);

      const verifierCount = await getAllVerifiers(networkId);
      const res = verifierCount.data.verifiers;
      setVerifier(res);
      setFilteredData(res);
      setLoading(false);
    } catch (err) {
      setError(err);
      toast.warning(
        "Hmm. Something doesn't seem right. Try refreshing or logging in again.",
        toastWarning()
      );
      setLoading(false);
    }
  };

  useEffect(() => {
    fecthVerifierData();
  }, []);

  const handleSearch = (query) => {
    setSearchItem(query);
    const filtered = verifier.filter(
      (organization) =>
        organization.orgName &&
        organization.orgName.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredData(filtered);
  };

  // copy DID
  const handleCopy = (id) => {
    navigator.clipboard.writeText(id).then(
      (id) => {
        toast.success("DID copied successfully", toastSuccess());
      },
      (err) => {
        toast.error("Oops! Try Again Later." + err, toastError());
      }
    );
  };

  const handleClickDetails = (data) => {
    navigate("/verifiers/details", { state: { data: data } });
  };

  // No results content
  const noResultContent = {
    heading: "Sorry! No Results Found.",
    subHeading: [
      "We couldn’t find what you were looking for. Please try other ways.",
    ],
  };
  const noAddedContent = {
    heading: "No Verifier added yet",
    subHeading: ["Send Invitation to Verifier Organization"],
  };

  const sortedVerifier = filteredData.sort(
    (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
  );

  const tooltipContent = ` 
            The Verifier, typically an organization like a business or service
            provider, ensures the trust and authenticity of credentials
            presented by holders. 
            `;
  const tooltipContentTwo = ` 
                        They request pertinent credentials to verify
            qualifications or compliance with specific requirements. Utilizing
            decentralized identifiers (DIDs) and advanced cryptographic
            techniques, the Verifier validates the authenticity and integrity of
            the submitted credentials.
            `;
  const tooltipContentThree = ` 
                         This validation process confirms the trustworthiness of the
            information, enabling the Verifier to make data-driven decisions
            regarding the holder, such as granting access or authorizing
            transactions. This technical process is critical for ensuring secure
            and reliable interactions that depend on verified digital
            credentials.
            `;

  return (
    <>
      {openModal && (
        <AddNewVerifierModal
          show={openModal}
          handleClose={() => setOpenModal(false)}
          refetchData={fecthVerifierData}
        />
      )}
      <div className="right-container">
        <div className="main-header">
          <Header
            title="Verifiers"
            infoTooltip={true}
            tooltipContent={tooltipContent}
            tooltipContentTwo={tooltipContentTwo}
            tooltipContentThree={tooltipContentThree}
            tooltipPlacement="right"
          />
        </div>
        <div className="inner-body-container">
          <div className="requests-container">
            <div className="request-header">
              <div className="search-bar-box verifier-search-wiz">
                <input
                  type="search"
                  className="search-bar"
                  placeholder="Search Organization"
                  id="search-text-box"
                  value={searchItem}
                  onChange={(e) => handleSearch(e.target.value)}
                />
              </div>
              <div className="custom-select d-flex align-items-center add-verifier-wiz">
                <div className="select-box">
                  <button
                    className="dsm-btn-light mx-1"
                    onClick={fecthVerifierData}
                  >
                    <i class="bi bi-arrow-counterclockwise fw-bolder "></i>
                  </button>
                  <button
                    type="button"
                    className="dsm-btn-primary"
                    onClick={() => setOpenModal(true)}
                  >
                    Add +
                  </button>
                </div>
              </div>
            </div>
            <div className="requests-body">
              <Table borderless className="requests-table submit-proof-table">
                <thead>
                  <tr>
                    <th>Organization Name</th>
                    <th>Email ID</th>
                    <th>Decentralised Identity</th>
                    <th style={{ textAlign: "center" }}>Date</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr className="empty-screen-tr">
                      <td colSpan={5}>
                        <div className="mt-4 d-flex align-items-center justify-content-center">
                          <Spinner animation="border" variant="primary" />
                        </div>
                      </td>
                    </tr>
                  ) : verifier.length === 0 ? (
                    <tr className="empty-screen-tr">
                      <td colSpan={5}>
                        <EmptyScreen
                          content={noAddedContent}
                          emptyScreenImage={NoDataImage}
                        />
                      </td>
                    </tr>
                  ) : (
                    <>
                      {sortedVerifier?.length == 0 ? (
                        <tr className="empty-screen-tr">
                          <td colSpan={5}>
                            <EmptyScreen
                              content={noResultContent}
                              emptyScreenImage={NoResultImage}
                            />
                          </td>
                        </tr>
                      ) : (
                        filteredData?.map((organization) => {
                          return (
                            <tr key={organization?.id}>
                              <td
                                onClick={() => handleClickDetails(organization)}
                              >
                                <span
                                  style={{
                                    color: "#4398CD",
                                    cursor: "pointer",
                                    textDecoration: "underline",
                                  }}
                                >
                                  {organization?.orgName}
                                </span>
                              </td>
                              <td>{organization?.userName}</td>
                              {/* <td>
                                {organization?.verifierDiD
                                  ? organization?.verifierDiD
                                  : "-"}
                              </td> */}
                              <td>
                                {organization?.verifierDiD ? (
                                  <>
                                    <img
                                      className="img-fluid copy-icon"
                                      src={CopyIcon}
                                      alt="copy"
                                      onClick={() =>
                                        handleCopy(organization?.verifierDiD)
                                      }
                                    />
                                    <span>{organization?.verifierDiD}</span>
                                  </>
                                ) : (
                                  "-"
                                )}
                              </td>
                              <td style={{ textAlign: "left" }}>
                                {formatDate(organization?.updatedAt)}
                              </td>
                              <td>
                                <span
                                  className={
                                    organization?.active === true
                                      ? "success-badge"
                                      : "pending-badge"
                                  }
                                >
                                  {organization?.active === true
                                    ? "Active"
                                    : "Pending"}
                                </span>
                              </td>
                            </tr>
                          );
                        })
                      )}
                    </>
                  )}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Verifier;
