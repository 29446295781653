import React from "react";
import { Modal } from "react-bootstrap";
import { formatDate } from "../../util/inputvalidations";

const ServiceViewModal = ({ show, handleClose, data }) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="md"
      className="dsm-modal-popup"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {data?.name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <label htmlFor="update-at" className="mb-0 inputbox-label">
            Published On{" "}
          </label>
          <div className="d-flex align-items-center gap-2 mb-3">
            <p style={{ color: "1C1C1C", marginBottom: 0 }}>
              <small>{formatDate(data?.updatedAt)}</small>
            </p>
          </div>{" "}
          <label htmlFor="update-at" className="mb-0 inputbox-label">
            Schema ID
          </label>
          <div className="d-flex align-items-center gap-2 mb-3">
            <p style={{ color: "1C1C1C", marginBottom: 0 }}>
              <small>{data?.schemaId}</small>
            </p>
          </div>
          <label htmlFor="update-at" className="mb-0 inputbox-label">
            Credential Defination ID
          </label>
          <div className="d-flex align-items-center gap-2 mb-3">
            <p style={{ color: "1C1C1C", marginBottom: 0 }}>
              <small>{data?.credDefId}</small>
            </p>
          </div>
          <label htmlFor="update-at" className="mb-2 inputbox-label">
            Attributes
          </label>
          {data.attributes.map((attribute, index) => (
            <div className="mb-1" key={index}>
              <label className="text-capitalize" style={{ color: "#777777" }}>
                {attribute}
              </label>
            </div>
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="dsm-btn-secondary"
          onClick={handleClose}
        >
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ServiceViewModal;
